import Styles from './ZoomButton.scss';

import Template from './ZoomButton.hbs';
import Lightbox from 'views/components/lightbox/Lightbox.svelte'

export default BaseView.extend({

    // Events for this view
    events: {
        click: 'openLightbox'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     This object is passed on creation
     */
    initialize(options) {

        _.extend(this, options);

        _.bindAll(
            this,
            'openLightbox'
        );

        if (this.imageElement) {
            this.imageElement.on('click', this.openLightbox);
        }

        // Compile the DOM element passing options
        this.setElement(Template({
            Styles
        }));
    },

    openLightbox(e) {
        this.stopAllEvents(e);
        this.addSvelteChildView(
            document.body,
            Lightbox,
            {
                slides: [
                    {
                        url: this.contextUrl,
                    },
                ],
            }
        )
    }

});
