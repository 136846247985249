import Styles from './ModelAnswer.scss';

import Template from './ModelAnswer.hbs';
import Util from 'util/util';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import RenderSpecialElements from 'util/RenderSpecialElements';
import Lightbox from 'views/components/lightbox/Lightbox.svelte';
import Button from 'views/components/button/Button';

export default BaseView.extend({

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        '.js-model-answer-description img': {
            // Default options
            global: {
                // Set template for the img tag
                template: DefaultImageElement
            }
        }
    },

    events: {
        'click .js-open-lightbox': 'openLightbox',
    },

    initialize(options) {

        // Make options accessible within this
        _.extend(this, options);

        if (this.answerObject === undefined) {

            window.sentry.withScope(scope => {
                scope.setExtra('taskInfoJson', this.parentView.model.get('task_info_json'));

                window.sentry.captureMessage('ModelAnswer loaded while answer object is empty');
            });

            // Stop further execution
            return false;

        }

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            description: Util.renderContentSafely(this.answerObject.text),
            file: this.answerObject.file,
        }));

        // Add download button if model answer is a non-image file
        if (this.answerObject.file && !this.answerObject.file.isImage) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Download model answer'),
                    icon: 'file-download',
                    inline: true,
                    callback: () => {
                        window.open(
                            '/edu_files/open/' + this.answerObject.file.id + '/' + this.answerObject.file.fileIdHash,
                            '_blank'
                        )
                    }
                }),
                '.js-download-button'
            )
        }

        // Render the special elements for model answer
        _.defer(RenderSpecialElements, {}, this);
    },

    openLightbox() {
        this.addSvelteChildView(
            document.body,
            Lightbox,
            {
                slides: [
                    {
                        url: `/edu_files/open/${this.answerObject.file.id}/${this.answerObject.file.fileIdHash}`,
                    },
                ],
            }
        )
    }
});
