<script>
    import Icon from 'views/components/icon/Icon.svelte';
    import Spinner from 'views/components/spinner/Spinner.svelte'

    /* Changelog from Backbone's button:
        * buttonElement is removed, we shouldn't access elements directly
        * labelElement is removed, we shouldn't access elements directly
        suggestion: use label attribute directly
    */

    /**
     * @deprecated Should use svelte properties instead.
     */
    export function enable() {
        console.warn('Calling deprecated enable method on button');

        disabled = false;

        if (hasSpinner) {
            hasSpinner = false;
        }
    }

    /**
     * @deprecated Should use svelte properties instead.
     * @param {boolean} startSpinner start spinner
     */
    export function disable(startSpinner = false) {
        console.warn('Calling deprecated disable method on button');

        disabled = true

        if (startSpinner) {
            hasSpinner = true
        }

        if (!startSpinner && hasSpinner) {
            hasSpinner = false;
        }
    }

    /**
     * @deprecated Should use svelte properties instead.
     * @param {boolean} newState force new state if param is present
     */
    export function toggle(newState) {
        console.warn('Calling deprecated toggle method on button');

        const state = newState === undefined ? disabled : newState

        if (state) {
            enable()
        } else {
            disable()
        }
    }

    /**
     * @deprecated Should use svelte properties instead.
     * @param {boolean} showSpinner show spinner if true, hide if false
     */
    export function toggleSpinner(showSpinner = false) {
        console.warn('Calling deprecated toggleSpinner method on button');

        hasSpinner = showSpinner
    }

    /**
     * @deprecated Should use svelte properties instead.
     * @param {string} newLabel new label for button
     */
    export function changeLabel(newLabel) {
        console.warn('Calling deprecated enable method on button');

        label = newLabel
    }

    export let inline = false;
    export let label = '';
    export let type = null
    export let title = null
    export let theme = 'primary'
    export let size = 'small'
    export let icon = null
    export let iconRight = null
    export let callback = null;
    export let disabled = false;
    export let hasSpinner = false;

    // Listen to the disabled state change, if disabled is set to
    // false, which re-enables the button. We should also hide/remove
    // the spinner if its enabled
    $: changedDisableState(disabled)

    function handleClick(e) {
        if (disabled) {
            return;
        }

        if (callback) {
            callback(e);
        }
    }

    function changedDisableState(state) {
        if (!state && hasSpinner) {
            hasSpinner = false;
        }
    }

</script>

<div class="holder" class:is-inline={inline}>
    <button
        class={[
            'no-print', 'button',
            theme && `theme-${theme}`,
            size && `size-${size}`,
        ].join(' ')}
        {disabled}
        {type}
        {title}
        on:click={handleClick}
    >
        <span class="container">

            {#if icon}
                <span class="icon" data-button-icon>
                    <Icon name={icon}></Icon>
                </span>
            {/if}

            {#if label}
                <span>{label}</span>
            {/if}

            {#if iconRight}
                <span class="icon" data-button-icon>
                    <Icon name={iconRight}></Icon>
                </span>
            {/if}

            {#if hasSpinner}
                <span class="spinner">
                    <Spinner isInsideButton="true"></Spinner>
                </span>
            {/if}
        </span>
    </button>
</div>

<style lang="scss">
    .holder:not(.is-inline) {
        display: flex;
        flex-direction: column;
    }

    .holder.is-inline {
        display: contents;
    }

    .button {

        position: relative;
        justify-content: center;

        &:disabled {
            @include button-disabled-state;
        }

        &:not(:disabled) {

            @include button-hover-state;
            @include button-active-state;

            &:focus:not(&:active) {
                @include button-focus-state;
            }

        }

        &.theme- {

            &primary {
                @include button;
            }

            &secondary {
                @include button('secondary');
            }

            &outlined {
                @include button('outlined');
            }

            &transparent {
                @include button('transparent');
            }
        }

        &.size- {
            &medium {
                @include large-text;

                padding: 12px $spacing-16;

                :global(svg) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .container {
            align-items: center;
            gap: $spacing-8;

            .icon {
                display: inline-flex;
            }

            .spinner {
                position: absolute;
                display: block;

                margin: 0 auto;
                left: 0;
                right: 0;
                top: 0;

                height: 100%;

                :global(circle) {
                    stroke: $blue-black;
                }
            }
        }
    }

</style>
