<script>
    import UploadModel from 'models/UploadModel'
    import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte'
    import Icon from 'views/components/icon/Icon.svelte'

    export let label = null
    export let icon = null
    export let hasToggle = false
    export let toggleState = false
    export let subItems = null
    export let isSubItem = false
    export let uploadDetails = null
    export let callback = () => {}
    export let closeDropdown = () => {}

    let uploadModel
    let toggleSwitch

    function onClickItem() {
        if (hasToggle) {
            toggleSwitch.toggleState()
            return
        }

        callback()
        closeDropdown()
    }

    function addUploadModel(buttonElement) {
        if (uploadDetails) {
            uploadModel = new UploadModel({
                ...uploadDetails,
                element: jQuery(buttonElement)
            })
            uploadModel.on('change:url', (model) => {
                callback(model)
            })
        }
    }
</script>

{#if subItems}
    <details>
        <summary class="dropdown-item">
            {#if icon}
                <div class="icon">
                    <Icon name={icon}></Icon>
                </div>
            {/if}
            {#if label}
                <div class="label">{label}</div>
            {/if}
            <div class="arrow">
                <Icon name="arrow-down"></Icon>
            </div>
        </summary>
        <div>
            {#each subItems as item}
                <svelte:self {...item} isSubItem={true} {closeDropdown} />
            {/each}
        </div>
    </details>
{:else}
    <button
        class="dropdown-item"
        class:sub-item={isSubItem}
        use:addUploadModel
        on:click={onClickItem}
    >
        {#if icon}
            <span class="icon">
                <Icon name={icon}></Icon>
            </span>
        {/if}

        {#if hasToggle}
            <span class="toggle">
                <ToggleSwitch bind:this={toggleSwitch} isChecked={toggleState} {callback}></ToggleSwitch>
            </span>
        {/if}

        {#if label}
            <span class="label">{label}</span>
        {/if}
    </button>
{/if}

<style lang="scss">
    .dropdown-item {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 44px;
        padding: 0;
        font-size: 1rem;
        overflow: hidden;
        cursor: pointer;
        background: $white;
        color: $blue-black;
        fill: $blue;
        border: none;
        border-radius: 12px;
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }

        &:hover {
            background-color: $white-highlight;
        }

        &.sub-item {
            margin-top: 4px;
            background: $background-gray;

            &:last-child {
                margin-bottom: 4px;
            }

            &:hover {
                color: $black;
            }
        }

        .label {
            padding-left: 1rem;
            padding-right: 1rem;
            white-space: nowrap;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 1rem;

            :global(svg) {
                width: 20px;
                height: 20px;
                fill: $blue;
            }
        }

        .toggle {
            padding-left: 1rem;
        }

        .arrow {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            :global(svg) {
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 1rem;
                fill: $blue-black;
                transition: transform 0.25s;
            }
        }
    }

    details[open] .dropdown-item .arrow :global(svg) {
        transform: rotate(180deg);
    }
</style>
