import Styles from './Modal.scss';

import Template from './Modal.hbs';
import Button from 'views/components/button/Button'
import Spinner from 'views/components/spinner/Spinner.svelte';
import TitleItem from 'views/components/modals/parts/titleItems/TitleItem';
import Util from 'util/util';

export default class Modal extends BaseView {

    // Events for the new modal
    get events() {
        return {
            'click .js-close-modal': 'close'
        }
    }

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize({
        isFullscreen
    }) {

        // Create a boolean whether the modal can be closed or not
        this.canBeClosed = true

        // Create an array for holding the view stack
        this.subViewStack = []

        // Make this accessible in the following function
        this.bindAll([
            'add',
            'addButtons',
            'close',
            'createSubview',
            'goBackInStack',
            'handleOptions',
            'navigateStackTo',
            'open',
            'removeCloseButton',
            'onKeyUp'
        ])

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            cid: this.cid,
            isFullscreen
        }));
    }

    /**
     * openModal
     *
     * This function will trigger the modal to open. It will handle the
     * given configuration and load the subview into the right element.
     *
     * @param  {BackboneView} SubView This is the view which should be shown in modal
     * @param  {Object} options This are the options, used to configure the modal
     */
    open(SubView, options) {

        this.keyCodeCallbackMap = new Map();
        $(document).on('keyup', this.onKeyUp);

        // Destroy all the childviews
        this.destroyChildViews();

        // Reenable closing the modal
        this.addCloseButton()

        // Clear the subViewStack
        this.subViewStack = [];

        // Let the handleOptions clear all buttons
        options.clearButtons = true;

        // If there is a fetchModel (model that should be fetched first)
        if (options.fetchModel || options.fetchCollection) {

            // Create a spinner
            const spinnerView = this.addSvelteChildView('.js-modal-included-view', Spinner, {
                hasModalParent: true
            })

            // Determine if model or collection
            var type = (options.fetchModel) ? 'model' : 'collection';

            // Distill fetchable for model or collection
            var fetchable = options.fetchModel || options.fetchCollection;

            // Start fetching the fetchable
            fetchable.fetch({

                // When the fetching is succesful
                success: () => {

                    // Bind the fetchable to the options
                    options[type] = fetchable

                    // Remove the spinner by destroying it
                    this.unregisterAndDestroyChildView(spinnerView)

                    // Initialize the subview using the createSubview function
                    const subView = this.createSubview(SubView, options)

                    // Add the subview to the DOM to make it visible
                    this.$('.js-modal-included-view').html(subView.$el);

                }
            });

            // Else just do the normal stuff
        } else {

            // Initialize the subview using the createSubview function
            var subView = this.createSubview(SubView, options);

            // Add the subview to the DOM to make it visible
            subView.appendTo(this.$('.js-modal-included-view'));
        }

        // Lock the html element from scrolling
        this.el.setAttribute('data-disable-scrolling', '')

        // Show modal, by setting display to flex
        this.$el.css('display', 'flex');

        TweenMax.set(this.$el, {
            opacity: 0
        });
        TweenMax.set(this.$('.js-modal-component-container'), {
            scale: 0.8,
            opacity: 0.4
        });

        TweenMax.to(this.$el, {
            opacity: 1,
            duration: 0.2,
            ease: 'expo.inOut',
        });
        TweenMax.to(this.$('.js-modal-component-container'), {
            scale: 1,
            opacity: 1,
            duration: 0.35,
            ease: 'back.inOut',

            // Callback for when animation is complete
            onComplete: () => {

                // For if the modal has any kind of focusable elements, focus on the first one and trap focus
                // within the modal.
                this.setFocusTrap()

                // Trigger the modal is open state
                this.trigger('modalIsOpen');

            }
        });

        this.isOpen = true

        // TODO why is this being called before window.app is ready?
        if (window.app) {
            window.statsTracker.trackEvent(
                window.app.controller.activePath.join('/'),
                'Modal opened: ' + options.title
            )
        }
    }

    /**
     * add
     *
     * This is the function to add a subview to the modal. It will use the stackable
     * functionality of the modal. It will detach the old view, create a new subview
     * and show the new subview. The logic for handling options and adding subview to
     * stack will be handled in the createSubView function
     *
     * @param  {type} SubView description
     * @param  {type} options description
     *
     * @return {Promise<void>} Promise that fulfills when TweenMax callback is done
     */
    add(SubView, options) {
        return new Promise(resolve => {
            const containerWidth = (this.$('.js-modal-component-container').width())

            TweenMax.to(this.subView.$el, {
                duration: 0.2,
                x: -(containerWidth),
                ease: 'expo.inOut',
                onComplete: () => {

                    // Detach view from DOM
                    this.subView.detach();

                    // Initialize the subview using the createSubview function
                    var subView = this.createSubview(SubView, options);

                    TweenMax.set(subView.$el, {
                        x: containerWidth
                    });

                    // Add the subview to the DOM to make it visible
                    subView.appendTo(this.$('.js-modal-included-view'));

                    TweenMax.to(subView.$el, {
                        x: 0,
                        duration: 0.2,
                        ease: 'expo.inOut'
                    })

                    // For if the modal has any kind of focusable elements, focus on the first one and trap focus
                    // within the modal.
                    this.setFocusTrap()

                    resolve()

                }
            });
        })
    }

    /**
     * createSubview
     *
     * This function will handle the initialization of the subview. It will
     * check if there is already a subview, destroy it, and initialize the
     * new subview
     *
     * @param  {BackboneView} SubView   description
     * @param  {Object} options         This are the options, used to configure the modal
     * @return {BackboneView}           This is the created subview
     */
    createSubview(SubView, options) {

        // Create a new Subview and store it in the this object
        this.subView = new SubView(options);

        // Register the subview so it can be destroyed nicely
        this.registerChildView(this.subView);

        // Register the view to the subView stack (for stackable modal functionality)
        this.subViewStack.push({
            view: this.subView,
            options
        });

        // Get the index for this subview
        var subViewIndex = (this.subViewStack.length - 1);

        // Create title items for this view
        this.createTitleItems(options, subViewIndex)

        // Parse the options rightfully
        this.handleOptions(options, subViewIndex)

        // Return the created subView
        return this.subView;
    }

    /**
     * goBackInStack
     *
     * Method to go back one level into the stack for a stackable modal
     *
     */
    goBackInStack() {

        // Navigate to stack minus one for normalizing and one to go back
        // normalizing needs to be done because array starts at 0 and length
        // starts at 1
        this.navigateStackTo(this.subViewStack.length - 2);
    }

    /**
     * createTitleItems
     *
     * This function will handle the creation of title items. It uses the options
     * to determine the value for the title items and it will bind the title item
     * to the navigateStackTo function. It will also register it's childviews for
     * clean removal of all the subviews to prevent memory leaks.
     *
     * @param  {Object} options This is the options object as passed by initialization
     * @param  {int} index      This is the index for the view, to use in navigateStackTo
     */
    createTitleItems(options, index) {

        // If title is empty or undefined, hide title container and add styling to the close button
        // such that it 'floats' above the modal's content.
        this.$('.js-title-items').toggle(!_.isEmpty(options.title))
        this.$('.js-title').toggleClass(Styles['modal-title--empty-title'], _.isEmpty(options.title))

        // When this is the first subview
        if (index === 0 || options.doNotCreateModalCrumblePath) {

            // Clear the title items
            this.$('.js-title-items').empty();
        }

        options.callback = _.partial(this.navigateStackTo, index);
        options.index = index

        // Create a title item for this view
        this.addChildView(new TitleItem(options), '.js-title-items');

    }

    /**
     * handleOptions
     *
     * the handleOptions function will check what options are set and will
     * execute the right actions accordingly. This is done for each created modal
     * as well als stackable modalviewss
     *
     * @param  {Object} options options object containing options for modal
     * @param  {Number} index of the current view in the modal stack
     */
    handleOptions(options, index) {

        // Remove styling added by previous view
        this.$('.js-modal-component-container').removeClass(this.addedStyling);

        // Check if there are options for this modal
        if (options !== undefined) {

            // Check if the buttons should be cleared
            if (options.clearButtons === true) {
                this.getButtons().forEach((buttonView) => buttonView.destroy());
                this.$('.js-modal-buttons').empty()
            }

            // Check if there are options for a button
            if (options.buttons) {

                // Loop through each button to determine position and create it
                _.each(options.buttons, this.addButtonToDOM, this)

            }

            // Some modal views add buttons to themselves with an addButtons method.
            if (this.subViewStack[index].view.addButtons instanceof Function) {
                // Call addButtons with the current view as the `this` context.
                this.subViewStack[index].view.addButtons.call(this.subViewStack[index].view)
            }

            if (options.removeCloseButton) {
                this.removeCloseButton()
            } else {
                this.addCloseButton()
            }

            this.addedStyling = []
            if (options.style) {
                this.addedStyling.push(Styles['modal-container--' + options.style])
            }
            // Add the theme modifier class to the container
            this.$('.js-modal-component-container').addClass(
                // Select from styles list
                this.addedStyling
            );

            // Only open sidebar if user is not on mobile since the sidebar (should) only give(s)
            // Additional information
            if (!ISMOBILE) {

                const sidepanelWidth = this.$('.js-modal-sidepanel').width();

                // Check if there are options for a sidepanel
                if (options.sidepanel !== undefined &&
                    options.sidepanel !== null &&
                    options.sidepanel.title !== undefined &&
                    options.sidepanel.content !== undefined
                ) {

                    // Fill the sidebar title with the option's sidepanel title
                    this.$('.js-modal-sidebar-title').text(options.sidepanel.title);

                    // Fill the sidebar content with the option's sidepanel content
                    this.$('.js-modal-sidebar-text').html(options.sidepanel.content);

                    // Add the has-sidepanel class
                    this.$el.addClass(Styles['has-sidepanel']);

                    TweenMax.set(this.$('.js-modal-sidepanel'), {
                        x: -(sidepanelWidth)
                    });
                    TweenMax.to(this.$('.js-modal-sidepanel'), {
                        x: 0,
                        duration: 0.2,
                        ease: 'expo.inOut'
                    });
                } else {
                    TweenMax.to(this.$('.js-modal-sidepanel'), {
                        x: -(sidepanelWidth),
                        duration: 0.2,
                        ease: 'expo.inOut',
                        onComplete: () => {
                            this.$el.removeClass(Styles['has-sidepanel'])
                        }
                    })
                }
            }
        }
    }

    /**
     * addButtons
     *
     * This function will add buttons to the modal. This can be used
     * to create the buttons after a new view is initialized
     *
     * @param  {Object|Array} buttons   Object containing the buttons
     * @param  {boolean} clearButtons   Boolean whether the buttons should be cleared
     * @return {Array|Object}                  Return array or object with created views
     */
    addButtons(buttons, clearButtons) {

        // Check if there are options for a button
        if (!buttons) {
            return
        }

        // Check if the buttons should be cleared
        if (clearButtons === true) {
            this.getChildViewsOfInstance(Button).forEach((buttonView) => buttonView.destroy());
        }

        // Add button options to subViewStack so buttons can be used again when going to a previous item in the
        // model subViewStack.
        const topSubViewOptions = this.subViewStack[this.subViewStack.length - 1].options
        topSubViewOptions.clearButtons = clearButtons
        topSubViewOptions.buttons = buttons

        if (Array.isArray(buttons)) {
            // Else if buttons is array, we can map the button to the
            // button view. This way we can interact with button views
            return buttons.map(
                (button, index) => this.addButtonToDOM(button, index, buttons)
            )
        }

        const buttonViews = {}

        /**
         * Attach buttons to modal and return an object with the
         * button views that can be accessed by their label
         */
        for (const label of Object.keys(buttons)) {
            buttonViews[label] = this.addButtonToDOM(buttons[label], label, buttons)
        }

        return buttonViews

    }

    /**
     * getButtons
     *
     * This method returns an array with all the buttons
     *
     * @return {Array}  Array with all buttons
     */
    getButtons() {

        // Return all bound buttons
        return this.getChildViewsOfInstance(Button);
    }

    /**
     * addButtonToDOM
     *
     * This function will be called from an each function on buttons. It will
     * create a button view and add it to the right container.
     *
     * @param  {Object} button  Object with button options
     * @param  {string} label   Label for this button
     * @param  {Object} origin  Original buttons collection (array or object)
     * @return {Backbone.View}  Return the created button
     */
    addButtonToDOM(button, label, origin) {

        // If callback attribute is passed as a string, assume it is a function name
        // on the sub view of this modal.
        if (_.isString(button.callback)) {
            button.callback = this.subView[button.callback]
        }

        if (typeof button.callback === 'function') {
            button.callback = button.callback.bind(this.subView)
        }

        // Previously this was done by the button component, however
        // it should be controlled by the modal. Therefore add the keyCode
        // with callback to the keyCodeCallback map so let the modal handle
        // keyups so we can use the real button component
        if (button.keyCode) {
            this.keyCodeCallbackMap.set(
                button.keyCode,
                button.callback
            );
        }

        // Check if the origin is not an array
        if (!_.isArray(origin)) {

            // Store the label in the button object
            button.label = label;
        }

        var buttonView = this.addChildView(
            new Button(button),
            '.js-modal-buttons'
        );

        // When the bindButtonTo is not undefined
        if (button.bindButtonFunction !== undefined) {

            // Execute the bindButtonFunction and pass the buttonview
            button.bindButtonFunction(buttonView);
        }

        return buttonView;
    }

    /**
     * navigateStackTo
     *
     * This function will handle the navigation between title items. It will
     * dirty (because the subviews will be removed cleanly when modal closes) remove
     * all the views greater than the given index. And reattach the given view
     * to the modal. This will keep events and values intact.
     *
     * @param  {int} index This is the index to where we should navigate back
     */
    navigateStackTo(index) {

        // When the index is bigger then the number of subviews
        if ((this.subViewStack.length - 1) > index) {

            // Store the current subView into a variable
            var fromView = this.subView;

            // Distill the to view from the viewstack
            var toView = this.subViewStack[index];

            // Store clicked subview as active
            this.subView = toView.view;

            // Remove all the title items greater than the stacks one and the stacks one
            this.$('.js-title-item:gt(' + index + '),.js-title-item:eq(' + index + ')').remove();

            // Remove views in the stack beyond the index of toView.
            this.subViewStack.splice(index + 1)

            var containerWidth = (this.$('.js-modal-component-container').width());

            TweenMax.to(fromView.$el, {
                x: containerWidth,
                duration: 0.2,
                ease: 'expo.inOut',
                onComplete: () => {

                    // Destroy the active subview
                    this.unregisterAndDestroyChildView(fromView);

                    // Readd the previous view to viewstack
                    this.subView.attachTo(this.$('.js-modal-included-view'));

                    // For if the modal has any kind of focusable elements, focus on the first one and trap focus
                    // within the modal.
                    this.setFocusTrap()

                    TweenMax.set(this.subView.$el, {
                        x: -(containerWidth)
                    });

                    TweenMax.to(this.subView.$el, {
                        x: 0,
                        ease: 'expo.inOut',
                        duration: 0.2
                    })

                }
            })

            // Create title item for this view
            this.createTitleItems(toView.options, index)

            // Reexecute options
            this.handleOptions(toView.options, index)

        }
    }

    /**
     * addCloseButton
     *
     * Method for reenabling the close functionality of the modal
     *
     */
    addCloseButton() {

        // Hide the close button
        this.$('.js-close-modal').show().attr('disabled', false)

        // Set can be closed to true to reenable closing
        this.canBeClosed = true;
    }

    /**
     * removeCloseButton
     *
     * Method to remove the closebutton and prevent the modal from closing
     *
     */
    removeCloseButton() {

        // Hide the close button
        this.$('.js-close-modal').hide().attr('disabled', true)

        // Set can be closed to false to prevent closing from unhiding element
        this.canBeClosed = false;
    }

    /**
     * close
     *
     * This function will be called when the user clicks on the close icon. It
     * will close/hide the modal so the user can continue his work.
     *
     * @param {boolean} force for close
     * @returns {Promise<void>} a promise that is resolved when the animation ends
     */
    close(force = false) {
        // If a modal is open atop of the fullscreen modal, close that one as well.
        if (Backbone.View.Components.modal.isOpen && this.el.classList.contains('js-fullscreen')) {
            Backbone.View.Components.modal.close(force)
        }

        return new Promise((resolve) => {

            // Only execute function when modal can be closed
            if (!(this.canBeClosed === true || force === true)) {
                console.log('Modal is locked, cannot be closed!')
                return
            }

            TweenMax.to(
                this.$('.js-modal-component-container'), {
                    scale: 0.8,
                    opacity: 0.4,
                    duration: 0.25,
                    ease: 'back.inOut'
                }
            )

            TweenMax.to(this.$el, {
                opacity: 0,
                ease: 'expo.inOut',
                duration: 0.3,
                onComplete: () => {

                    // Trigger the modalClosed event
                    this.trigger('modalClosed');

                    // Hide the modal
                    this.$el.css('display', 'none');

                    // Destroy all the childviews
                    this.destroyChildViews();
                    delete this.subView

                    // Remove all keycode mappings
                    this.keyCodeCallbackMap = new Map();

                    this.canBeClosed = true

                    resolve()

                }
            })

            // Unlock the html element from scrolling
            this.el.removeAttribute('data-disable-scrolling')

            $(document).off('keyup', this.onKeyUp);

            this.isOpen = false
        })
    }

    onKeyUp(e) {
        const {keyCode, key} = e

        if (this.canBeClosed
            && key === 'Escape'
        ) {
            return this.close()
        }

        if (!this.keyCodeCallbackMap.size) {
            return
        }

        if (!this.keyCodeCallbackMap.has(keyCode)) {
            return
        }

        const callback = this.keyCodeCallbackMap.get(keyCode);

        if (typeof callback !== 'function') {
            return
        }

        callback();
    }

    /**
     * Set the focus to be trapped within the modal. If the modal has any modal buttons, set the focus on the last
     * button. Often this is the affirmitive button. If not, set the focus on the first focusable element within
     * modal excluding the cross for closing the modal, which is first in the list.
     */
    setFocusTrap() {
        const modalButtonElements = Util.getFocusableElements(this.el.querySelector('.js-modal-buttons'), true, false)
        if (modalButtonElements.length) {
            const focusableElementsInModal = Util.getFocusableElements(this.el, true, false)
            const focusIndex = _.findLastIndex(focusableElementsInModal, _.last(modalButtonElements))
            Util.setFocusTrap(this.el, focusIndex)
        } else {
            Util.setFocusTrap(this.el, this.canBeClosed)
        }
    }
}
