import Template from './Source1.hbs';
import ImageGrid from './ImageGrid.svelte'

// Images source view
export default class Source1 extends BaseView {

    initialize() {
        const source = this.model.get('source')

        // Translate old single-image format to new multi-image format.
        // {
        //     // If images can be opened in full screen. Only relevant if images.length === 1, otherwise omitted.
        //     "fullScreen": true,
        //      // Image size. Only relevant if images.length === 1, otherwise omitted.
        //     "size": "small|medium|large",
        //     "images": [
        //         {
        //             // Edu file ID + edu file hash, for example "141782/ed201b1e637bbb803a4b7967408dc35b"
        //             "url": ":fileId/:fileIdHash",
        //             // Caption of image, optional.
        //             "caption": ":caption",
        //             // Cited source of image, optional.
        //             "source": ":citation"
        //         }
        //     ],
        //     // Only relevant if images.length > 1. Omitted if images.length === 1.
        //     // Cannot be larger than images.length, cannot be < 1.
        //     "columns": 2
        // }
        let images = source.images
        if (!Array.isArray(images)) {
            images = source?.fileId ? [{
                url: `${source.fileId}/${source.fileIdHash}`,
                caption: source.caption,
                speechCaption: source.speechCaption,
                source: source.imageSource,
            }] : []
        }

        this.setElement(Template())
        this.addSvelteChildView(this.el, ImageGrid, {
            title: this.model.get('title'),
            images,
            // Ensure the displayed number of columns is no larger than
            // the number of images. Otherwise we end up with a lot of
            // unused space.
            columns: Math.min(
                isNaN(source.columns) ? 1 : source.columns,
                Math.max(images.length, 1),
            ),
            canZoom: images.length > 1 || source.fullScreen,
            singleImageSize: source.size,
            sourceId: this.model.id,
        })
    }

}
