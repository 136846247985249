var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-disable-scrolling class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n    <div class=\"js-content "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0))
    + "\">\n        <div class=\"js-element-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"element-container") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"active-element") : stack1), depth0))
    + "\">\n                <div class=\"center-panel\">\n                    <div class=\"work-on__taskgroup-title\">\n                        <span class=\"js-task-group-index work-on__taskgroup-sequence\"></span>\n                        <span class=\"js-task-group-introduction work-on__taskgroup-introduction\"></span>\n                    </div>\n                </div>\n                <div class=\"js-active-element\"></div>\n            </div>\n            <div class=\"js-side-element "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"side-element") : stack1), depth0))
    + "\"></div>\n        </div>\n    </div>\n    <nav class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"bottom-navigation") : stack1), depth0))
    + "\">\n        <div class=\"js-bottom-nav-left\"></div>\n        <div class=\"js-bottom-nav-right\"></div>\n    </nav>\n    <div class=\"js-side-navigation "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"side-navigation") : stack1), depth0))
    + "\"></div>\n</div>\n";
},"useData":true});