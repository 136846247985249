<script>
    import Icon from '../icon/Icon.svelte';
    import Util from 'util/util';
    import Spinner from '../spinner/Spinner.svelte';
    import { fade, scale } from 'svelte/transition';

    export let title = ''
    export let isFullscreen = false
    export let isOpen = true
    export let hasCloseButton = true
    export let canBeClosedWithEscapeKey = false

    // Add margin to the inside of the modal, so that content aligns with the title
    export let hasMargin = true

    // Show a spinner until this request has loaded
    export let request = null

    function containerCreated(element) {
        setFocusTrap(element)

        window.statsTracker.trackEvent(
            window.app.controller.activePath.join('/'),
            'Modal opened: ' + title
        )
    }

    function setFocusTrap(element) {
        let firstFocusIndex

        // If there are buttons, focus on the last button
        if (element.querySelector('.buttons button')) {
            firstFocusIndex = -1

        // If there a no buttons, focus on the first element in the modal (skipping the close button)
        } else if (hasCloseButton) {
            firstFocusIndex = 1
        } else {
            firstFocusIndex = 0
        }

        Util.setFocusTrap(element, firstFocusIndex)
    }

    function onKeydown(e) {
        if (isOpen && canBeClosedWithEscapeKey && e.key === 'Escape') {
            isOpen = false
        }
    }
</script>

<svelte:document on:keydown={onKeydown} />

{#if isOpen}
    <div
        data-disable-scrolling
        class="background"
        out:fade={{ duration: 350 }}
    >
        <div
            class="container"
            class:is-fullscreen={isFullscreen}
            role="dialog"
            aria-modal="true"
            use:containerCreated
            out:scale={{ duration: 350, start: 0.8 }}
        >

            <div class="title" class:has-close-button={hasCloseButton}>
                <slot name="title">
                    {title}
                </slot>
            </div>

            {#if hasCloseButton}
                <button class="close" on:click={() => { isOpen = false }}>
                    <Icon name="cross"></Icon>
                </button>
            {/if}

            <div class="content" class:has-margin={hasMargin}>
                {#await request}
                    <Spinner hasModalParent={true}></Spinner>
                {:then}
                    <slot></slot>
                {/await}
            </div>

            <div class="buttons">
                {#await request then}
                    <slot name="buttons"></slot>
                {/await}
            </div>

        </div>
    </div>
{/if}

<style lang="scss">
    .background {
        @include z-index-modal;

        position: fixed;
        inset: 0;
        display: flex;
        background: $black-50;
        animation: backgroundIn 0.35s forwards;

        @keyframes backgroundIn {
            0% { opacity: 0; }
        }

        .container {
            display: flex;
            flex-direction: column;
            min-width: min(100%, 560px);
            max-width: 820px;
            max-height: calc(100% - 32px);
            margin: auto;
            padding: 0;
            border-radius: 16px;
            border: none;
            background: $white;
            animation: containerIn 0.35s forwards;

            @keyframes containerIn {
                0% {
                    transform: scale(0.8);
                    opacity: 0.4;
                }
            }

            &.is-fullscreen {
                width: calc(100% - 32px);
                max-width: unset;
                height: calc(100% - 32px);
                max-height: unset;
                background: $background-gray;

                @media (max-width: 620px) {
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                }
            }
        }
    }

    .title {
        @include small-title-text;

        padding: 24px;

        &.has-close-button {
            padding-right: 64px;
        }

        &:empty {
            display: none;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        margin: 24px 24px 24px 16px;
        cursor: pointer;
        transition: transform 0.1s ease-in;

        &:hover {
            transform: scale(1.2);
        }

        :global(svg) {
            width: 24px;
            height: 24px;
            fill: $blue-black;
        }
    }

    .content {
        flex-shrink: 1;
        flex-grow: 1;
        overflow-y: auto;

        &.has-margin {
            padding: 0 24px 24px 24px;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        padding: 16px;
        border-top: 1px solid $line-gray;

        &:empty {
            display: none;
        }
    }
</style>
