<script>
    import StudentModel from 'models/StudentModel'

    // Optional student model, to show online state
    export let model = null
    const isStudentModel = model instanceof StudentModel

    // URL to the avatar
    export let avatar = isStudentModel ? model.get('avatar') : ''

    // Label to show next to avatar, typically name
    export let label = ''

    // Style to show inside the hero (on blue background)
    export let isHero = false

    // Only show the label on hover
    export let showLabelOnHover = false

    let isOnline = model?.get('online-state') === 'online'
    model?.listenTo(model, 'change:online-state', () => {
        isOnline = model.get('online-state') === 'online'
    })
</script>

<div class="container" class:show-label-on-hover={showLabelOnHover} class:is-hero={isHero}>
    <div
        class="holder"
        style:background-image="url('{avatar}')"
        component="avatar__holder">
        {#if isStudentModel}
            <div class="online-state" class:is-online={isOnline}></div>
        {/if}
    </div>
    {#if label}
        <span class="label">{label}</span>
    {/if}
</div>

<style lang="scss">
    .container {
        display: inline-flex;
        align-items: center;

        .holder {
            background: $white;
            border-radius: 100%;
            width: 28px;
            height: 28px;

            background-position: center;
            background-size: cover;
            display: flex;
            position: relative; /* necessary for the online state circle on the left center */
            align-items: center;
            justify-content: center;

            box-shadow: inset 0 0 0 2px rgba($black, 0.1);

            flex: none;

            &:has(.online-state) {
                margin-left: 4px;
            }
        }

        .online-state {
            position: absolute;
            left: -4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            outline: 1px solid $white;
            background-color: $status-gray;

            &.is-online {
                background-color: $status-green;
            }
        }

        .label {
            @include tooltip;
            position: initial;
            margin-left: $spacing-8;
        }

        &.is-hero {
            .holder {
                width: $spacing-48;
                height: $spacing-48;
                box-shadow: inset 0 0 0 2px $white-10;
            }

            .label {
                @include large-title-text;
                background: none;
            }
        }

        &.show-label-on-hover {
            position: relative;

            .label {
                display: none;
                position: absolute;
                left: 100%;
            }

            &:hover {
                .label {
                    display: block;
                }
            }
        }
    }
</style>
