import Util from 'util/util';
import Lightbox from 'views/components/lightbox/Lightbox.svelte'

var renderSpecialElements = function(options, parent) {

    // If the content contains any images that can be put into full screen, gather
    // these in a list to show in a single lightbox instance.
    const imageSlides = []
    let imageLightbox

    // Loop trough all the replaceable elements from the convert list
    _.each(parent.specialElementsConvertList, (elementOptions, element, scope) => {

        // If there is a scope use the scope else global
        const elements = scope ? parent.$(element, scope) : parent.$(element)

        // Loop trough each element found of this type
        _.each(elements, ($element) => {

            $element = $($element);

            // Create a holder for template options
            var templateOptions = {};
            var callbacklist = [];

            /*
             *
             *  Define template, with fallback to default template's elements
             *
             */

            var templateInfo;

            // Check if the template has its own replacement for this item
            if (elementOptions[options.template] !== undefined) {

                // Set the replacement template
                templateInfo = elementOptions[options.template];

                // Else check if there is a default fallback
            } else if (elementOptions.global !== undefined) {

                // Set the replacement template to default
                templateInfo = elementOptions.global;

            }

            /*
             *
             *  Do element specific stuff
             *
             */

            // Make a switch case of the to what
            var content;
            if ($element.is('img')) {

                // Get content from outerHTML instead of innerHTML
                content = $element[0].outerHTML;

                // If the image has any custom inline styling, keep it as-is.
                if ($element.attr('style')) {
                    return false;
                }

                // Check if the image should be an expression
                if ($element.hasClass('js-expression')) {

                    // Get Height and with
                    var height = $element.attr('height');
                    var width = $element.attr('width');

                    // Get the Base64 SRC and decode it
                    var source = $element.attr('src').split(',')[1];
                    var imgSVG = Util.base64Decode(source);

                    // When height and width are not 0, use them to scale SVG
                    if (height && width) {
                        imgSVG = $(imgSVG).attr('height', height + 'px').attr('width', width + 'px');
                    }

                    // Replace image with SVG
                    $element.replaceWith(imgSVG);

                    // Stop further execution
                    return false;
                }

                // Positioning
                if ($element.hasClass('source__inline-image-author--left')) {
                    templateOptions.image_position = 'left';
                } else if ($element.hasClass('source__inline-image-author--right')) {
                    templateOptions.image_position = 'right';
                } else {
                    templateOptions.image_position = 'middle';
                    callbacklist.push((element) => {
                        element.wrap('<div class="source__inline-image--block"></div>');
                    });
                }

                if ($element.hasClass('source__inline-image-author--small')) {
                    templateOptions.image_size = 'small';
                } else if ($element.hasClass('source__inline-image-author--medium')) {
                    templateOptions.image_size = 'medium';
                } else if ($element.hasClass('source__inline-image-author--large')) {
                    templateOptions.image_size = 'large';
                } else {
                    templateOptions.image_size = 'full-width';
                }

                // Fullscreen modal
                if (
                    $element.data('allow-fullscreen') === 'true' ||
                    $element.data('allow-fullscreen') === true
                ) {

                    imageSlides.push({
                        url: $element.attr('src'),
                        caption: $element.attr('data-image-caption'),
                    })
                    if (!imageLightbox) {
                        imageLightbox = parent.addSvelteChildView(
                            document.body,
                            Lightbox,
                            {
                                slides: imageSlides,
                                isOpen: false,
                            }
                        )
                    }

                    // Onclick create and show lightbox
                    callbacklist.push((element) => {
                        element.find('.js-image-container').on('click', () => {
                            imageLightbox.openAtSlide = $element.attr('src')
                            imageLightbox.isOpen = true
                        });
                    });

                    templateOptions.showFullScreenButton = true;
                }

                templateOptions.source = $element.data('image-resource');
                templateOptions.caption = Util.renderContentSafely($element.data('image-caption'));
                templateOptions.title = $element.data('image-title');

            } else {

                content = $element.html();

            }

            /*
             *
             *  Render template and replace
             *
             */

            // Check if there is a replacement
            if (templateInfo !== undefined) {

                // Fill template options' content with the content
                templateOptions.content = content;

                // Compile the template for this element
                var elementTemplate = templateInfo.template;

                // Get colors for custom template
                if (options.matchingTemplate === 'custom') {
                    templateOptions.custom_font = options.font;
                    templateOptions.custom_color = options.color;
                }

                // Compile options
                if (templateInfo.options !== undefined) {

                    // Loop trough each options
                    _.each(templateInfo.options, (option, index) => {

                        // Get options from element and add to template options
                        templateOptions[index] = $element.data(option);
                    }, parent);
                }

                // Parse with variables
                var newElement = $(elementTemplate(templateOptions));

                // Do replacement stuff
                $element.replaceWith(newElement);

                for (var callback of callbacklist) {
                    callback(newElement)
                }
            }

        });
    });
};

export default renderSpecialElements;
