export default Backbone.Model.extend({

    /**
     * save
     *
     * Save model to localStorage and try to sync it to the backend.
     */
    save() {

        // Create localStorage model with the current model type and model data.
        // Remember the localStorage model ID so it can be removed from
        // localStorage once the current model has been successfully synced to
        // the backend.
        var localStorageTypeModel = Backbone.Collection.localStorage.add({
            modelType: 'WordsResponseModel',
            modelData: this.attributes
        });

        this.localStorageModelID = localStorageTypeModel.id;
        this.sync();

    },

    /**
     * sync
     *
     * Sync exam report type and info to the backend. Remove the copy of the data
     * in localStorage if POST request succeeds.
     */
    sync() {
        $.post({
            url: '/training/save_response',
            timeout: 10000,
            data: this.attributes,
            success: (response) => {
                Backbone.Collection.localStorage.onSyncSuccess(this.localStorageModelID)

                // trigger showing the view with session results
                if (response.result.status === 'finished') {
                    Backbone.Collection.localStorage.trigger('endOfSession', response.result)
                }
            },
            error: (response) => {
                Backbone.Collection.localStorage.onSyncError(response.status, this.localStorageModelID)
            }
        })
    }
});
