<script>
    import Icon from 'views/components/icon/Icon.svelte'

    export let icon = ''
    export let color = 'blue'
</script>

<div class="container">
    {#if icon}
        <div class="icon {color}">
            <Icon name={icon}></Icon>
        </div>
    {/if}

    <div class="content">
        <div class="title">
            <slot name="title"></slot>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        @include box-shadow;

        display: flex;
        border-radius: 12px;
        width: 100%;
        background: $white;
        color: $blue-black;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 0 12px 12px;
            border-left: 12px solid currentColor;
            color: $blue;
            align-self: stretch;
            border-radius: 12px 0 0 12px;
            flex-shrink: 0;

            :global(svg) {
                height: 24px;
                width: 24px;
                fill: currentColor;
            }

            &.red {
                color: $status-red;
            }

            &.orange {
                color: $status-orange;
            }

            &.green {
                color: $status-green;
            }
        }

        .content {
            @include large-text;

            padding: 12px 8px 12px 12px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .title {
                margin-bottom: 4px;
                font-weight: 600;

                &:empty {
                    display: none;
                }
            }

            :global(a) {
                color: $blue;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media print {
        .container {
            box-shadow: none;
            background: none;
            border-radius: 0;
        }
    }
</style>
