import DefaultUploadStatusHandler from 'util/DefaultUploadStatusHandler';

export default class UploadModel extends Backbone.Model {

    // Get upload URL for the current environment.
    static get uploadURL() {

        if (DOMAIN.isDevelopment) {
            return '/upload';
        }

        if (DOMAIN.isBeta || DOMAIN.isLearnBeta) {
            return 'https://upload-beta.learnbeat.nl/upload';
        }

        return 'https://upload.learnbeat.nl/upload';
    }

    // String to be used for the accept attribute https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
    // includes a wide range of videos that FFMPEG running on the uploadserver can convert.
    static get videoMIMETypeAcceptString() {
        return [
            'video/*',
            '.mp4',
            '.mov',
            '.qt',
            '.mkv',
            '.ogg',
            '.asf',
            '.flv',
            '.f4v',
            '.swf',
            '.rm',
            '.mpv',
            '.m2v',
            '.wtv',
            '.mts',
            '.m2t',
            '.m2ts',
            '.vob',
        ].join(',')
    }

    // String to be used for the accept attribute https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
    // includes a wide range of videos that FFMPEG running on the uploadserver can convert, including video types,
    // since FFMPEG can just throw away the video streams from the file.
    static get audioMIMETypeAcceptString() {
        return [
            'audio/*',
            '.mp3',
            '.wav',
            '.m4a',
            '.aifc',
            '.adts',
            '.aiff',
            '.pcm',
            '.caf',
            '.au',
            '.wave',
            '.mpa',
            '.mp2',
            UploadModel.videoMIMETypeAcceptString
        ].join(',')
    }

    /**
     *
     * @param {String} accept
     * Optional. Accept string that filters which file types will be accepted.
     * @param {Boolean} multiple
     * Optional. If true, allow selecting and uploading multiple files in a single action.
     * @param {Function} customUploadStatusHandler
     * Optional. Alternative handler for change in upload status.
     * @param {String} disabledMessage
     * Optional. Message to show if uploading is disabled for demo users.
     * @param {Boolean} disableForDemo
     * Optional. If true, disable uploading.
     * @param {Boolean} doNotCreateInputElement
     * Optional. If true, skip creating a <input type="file">. Relevant for if no click interaction is needed.
     * @param {jQuery} element
     * Required. External element to which <input type="file"> will be attached to. This should be the click target
     * by which the user starts the file uploading process. Can be omitted if doNotCreateInputElement is true.
     * @param {String} uploadType
     * Required. Defines into which upload bucket the file will be uploaded. Uploadserver returns status 400 if omitted.
     */
    initialize({
        accept,
        multiple,
        customUploadStatusHandler,
        disabledMessage,
        disableForDemo,
        doNotCreateInputElement,
        element,
        uploadType,
    }) {

        // Pass 'this' to all the selected functions.
        _.bindAll(
            this,
            'createFileInputElement',
            'onFinishedProcessing'
        );

        if (uploadType !== undefined) {
            this.set('uploadType', uploadType)
        }

        if (disableForDemo && Backbone.Model.user.get('is_demo')) {

            $(element).on('click', _.partial(
                Backbone.View.layout.openStatus,
                disabledMessage || window.i18n.gettext(
                    'Uploading at this place is disabled for a demo account.'
                ),
                'warning'
            ));

        } else if (!doNotCreateInputElement) {
            this.createFileInputElement(
                element,
                accept,
                multiple,
            );
        }

        if (!doNotCreateInputElement) {

            // When model gets destroyed, remove the form element and disengage the click event listener to prevent
            // duplicate upload actions.
            this.once('destroy', () => {
                element.off('click')
            })
        }

        // React to file upload status changes use either a custom or the default handler.
        this.on('change:status', customUploadStatusHandler || DefaultUploadStatusHandler);

    }

    /**
     * The one and only method to upload files to the Uploadserver in UploadModel.
     *
     * @param {File|Blob} file                file or file blob to upload
     * @param {Element|Function} initiator    Optional indication of what object initiated the upload
     * @returns {Promise} Promise of request to the upload server
     */
    submitFile(file, initiator = null) {
        // Abort uploading if file is empty.
        // This may occur when the change event on the uploadInputElement triggers due to input element's value
        // changing from a previously uploaded file to no file, caused by the user cancelling out of the file chooser.
        if (!file) {
            return
        }

        this.set({
            status: 'loading',
            progress: 0,
            initiator,
        })

        const formData = this.createFormData(file)

        return $.ajax({
            url: UploadModel.uploadURL,
            type: 'POST',
            data: formData,
            contentType: false,

            // XHR with progress logic found at:
            // http://stackoverflow.com/a/19127053
            xhr: () => {

                // Create a new xml http request
                const xhr = new XMLHttpRequest()

                // Add progress event listener to the upload.
                xhr.upload.addEventListener('progress', (event) => {
                    if (event.lengthComputable) {
                        this.set('progress', (event.loaded / event.total) * 100)
                    }
                })

                return xhr
            },
            success: (data) => {
                if (data.uploadState === 'processing') {

                    this.listenToOnce(
                        Backbone.Model.user,
                        'finished-processing',
                        this.onFinishedProcessing
                    )

                    this.set('status', 'processing')

                } else {
                    this.set(data)
                    this.set('status', 'success')
                }
            },
            error: (error) => {

                this.handleError(error)

                this.set('status', 'error')
            }
        });
    }

    /**
     * Constructs payload for sending to the Uploadserver.
     *
     * @param {File|Blob} file  file or file blob to upload
     * @returns {FormData} formData object containing user ID, origin_url, upload type and the to be uploaded file
     */
    createFormData(file) {
        const formData = new FormData()

        formData.set('userid', Backbone.Model.user.id)
        formData.set('origin_url', window.location.pathname || window.location)
        formData.set('uploadType', this.get('uploadType'))
        formData.set('file', file)

        return formData
    }

    /**
     *
     * @param {jQuery} element      Click target that initiates the upload process.
     * @param {String} accept       Optional. Accept string that filters which file types will be accepted.
     * @param {Boolean} multiple    Optional. If true file input can select multiple files in a single action.
     */
    createFileInputElement(element, accept, multiple = false) {

        // Build the input field as a jQuery element, this is for the listener
        const uploadInputElement = document.createElement('input')
        uploadInputElement.type = 'file'
        uploadInputElement.accept = accept
        uploadInputElement.multiple = multiple
        uploadInputElement.style = 'height:0;width:0'
        uploadInputElement.id = this.cid

        // Change function for input file field,
        // this wil be called when value has been changed. (when use chooses file)
        uploadInputElement.addEventListener('change', (changeEvent) => {
            // When the model is still processing the previous file, throw a statusmessage
            // saying user has to wait. User needs to submit the file again to start another upload.
            if (this.get('status') === 'loading' || this.get('status') === 'processing') {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Please wait until the previous file is uploaded.'),
                    'error'
                )
                return
            }
            // Stop the unexpected stuff from parent elements. For example navigation.
            changeEvent.stopImmediatePropagation()
            changeEvent.stopPropagation()
            changeEvent.preventDefault()

            // Check if file exceeds the maximum allowed size
            const fileSize = changeEvent.currentTarget.files[0]?.size / 1024 / 1024
            if (fileSize > this.getMaximumAllowedSize()) {

                Backbone.View.layout.openStatus(
                    UploadModel.handleError413(this.get('uploadType')),
                    'error'
                )
                return
            }

            for (const file of changeEvent.currentTarget.files) {
                this.submitFile(file, element)
            }

        })

        // Unset the value of the file input element after the upload has succeeded or failed.
        // This way when uploading a file of the same name after the last one will still trigger
        // a 'change' event on the file input element, instead of doing nothing.
        this.on('change:status', (model, status) => {
            if (status === 'success' || status === 'error') {
                uploadInputElement.value = ''
            }
        })

        // Add an click listener to the element which supposed to be the image upload button
        element.eq(0).on('click', () => {

            // this code is added to make it possible for (internal) authors
            // to select a file by its edu_file_id, instead of uploading it again.
            if (Backbone.Model.user.getSetting('AuthorUseExistingFileIds')) {

                // ask the author for a file id
                const answer = window.prompt('What is the edu file or tham url?');

                // cancel button, do nothing
                if (answer === null) {
                    return
                }

                // empty input, use regular upload
                if (answer === '') {
                    uploadInputElement.click()
                    return
                }

                this.addFileThroughExistingFileId(answer)

            } else {
                uploadInputElement.click()
            }
        });

    }

    // Returns the maximum allowed file size in megabytes
    getMaximumAllowedSize() {
        return UploadModel.getMaximumAllowedSize(this.get('uploadType'))
    }

    // Returns the maximum allowed file size in megabytes
    static getMaximumAllowedSize(uploadType) {
        switch (uploadType) {
            case 'activity-image':
            case 'chapter-image':
                return 3

            case 'source-image':
            case 'wysiwyg-image':
            case 'portfolio-image':
            case 'training-image':
            case 'drawing-background':
                return 5

            case 'user-avatar':
                return 10

            case 'deliverable-preview-file':
            case 'source-audio':
            case 'task-audio':
                return 50

            case 'source-file':
            case 'admin-files':
                return 100

            case 'source-video':
            case 'support-attachment':
                return 150

            case 'deliverable-file':
                return 200

            // Use a general maximum if type is not set
            default:
                return 200
        }
    }

    /**
     * This method will handle error 413,
     * [Request too big] - The uploaded file is too big
     *
     * It will return the right message according to the upload type
     *
     * @param {string} uploadType   current upload type ('source-image', 'task-audio', etc.)
     *
     * @return {string}  Error string
     */
    static handleError413(uploadType) {
        return window.i18n.sprintf(
            window.i18n.gettext('This file is too big. The maximum file size is %d MB.'),
            UploadModel.getMaximumAllowedSize(uploadType),
        )
    }

    /**
     * This method will handle error 415,
     * [Media-type not supported] - Wrong type of file uploaded
     *
     * It will return the right message according to the upload type
     *
     * @param {string} uploadType   current upload type ('source-image', 'task-audio', etc.)
     * @param {string} errorMessage error message string from upload server
     *
     * @return {string}  Error string
     */
    static handleError415(uploadType, errorMessage) {
        switch (uploadType) {

            case 'source-image':
            case 'activity-image':
            case 'chapter-image':
            case 'portfolio-image':
            case 'user-avatar':
            case 'wysiwyg-avatar':
            case 'drawing-background':
                return window.i18n.gettext(
                    'File type not supported. Please upload your file in JPG, GIF or PNG format.'
                );

            case 'source-audio':
            case 'task-audio':
                switch (errorMessage) {
                    case 'Input file has no media streams':
                    case 'Input file has no audio streams':
                        return window.i18n.gettext(
                            'The uploaded file contains no audio data. Please choose a valid audio file.'
                        )
                    default:
                        return window.i18n.gettext(
                            'File type not supported. Please choose a valid audio file.'
                        )
                }
            case 'source-video':
                switch (errorMessage) {
                    case 'Input file has no media streams':
                    case 'Input file has no video streams':
                        return window.i18n.gettext(
                            'The uploaded file contains no video data. Please choose a valid video file.'
                        )
                    case 'Input file too large to be converted':
                        return window.i18n.gettext(
                            'The uploaded file is too large to be converted. Please choose a video in a MP4, QuickTime or WebM format.'
                        )
                    default:
                        return window.i18n.gettext(
                            'File type not supported. Please choose a valid video file.'
                        )
                }
            case 'admin-files':
                return window.i18n.gettext(
                    'File type not supported. Please upload your file in ZIP format.'
                )

            default:
                return window.i18n.gettext(
                    'The uploaded file does not match the criteria of allowed types.'
                );
        }
    }

    /**
     * onFinishedProcessing
     *
     * When the upload model has send a socket event to the upload model
     *
     * @param  {Object} data - upload data
     */
    onFinishedProcessing(data) {
        this.set(data)
        this.set('status', 'success');
    }

    handleError(error) {
        const data = this.attributes

        let errorMessageForSentry;

        switch (error.status) {

            // [Bad request] - No metadata available (no uploadType or can't read upload field)
            case 400:

                errorMessageForSentry = '[Bad request] - No metadata available (no uploadtype or can\'t read upload field)'

                data.errMessage = window.i18n.gettext(
                    'Uploading file failed, please try again.'
                )

                break

                // [Method not allowed] - not multipart form or invalid upload url
            case 405:

                errorMessageForSentry = '[Not allowed] - Invalid request sent from upload server'

                data.errMessage = window.i18n.gettext(
                    'Uploading file failed, please try again.'
                )

                break

                // [Request to big] - The uploaded file is too big
            case 413:
                data.errMessage = UploadModel.handleError413(this.get('uploadType'))
                break

                // [Media-type not supported] - Wrong type of file uploaded
            case 415:

                // Set message to tell user to use other type of file
                data.errMessage = UploadModel.handleError415(this.get('uploadType'), error.responseJSON?.message)
                break

            case 502:
                errorMessageForSentry = '[Bad gateway] - Invalid response received from upload server'

                data.errMessage = window.i18n.gettext(
                    'Uploading failed. Please try again or try a different file.'
                )

                break
        }

        if (errorMessageForSentry) {
            window.sentry.withScope(scope => {
                scope.setExtra('uploadData', data)
                scope.setExtra('errorObject', error)

                window.sentry.captureMessage(errorMessageForSentry)
            });
        }
    }

    /**
     * For converting base64 encoded image to a blob, also see:
     * https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     *
     * @param {string} base64WithPrefix string with  metadata prefixed base64
     *
     * @returns {Promise<Object>} Promise of request to the upload server
     */
    sendBase64ImageToUploadServer(base64WithPrefix) {

        /**
         * Base64 images that are used as src for an image are prefixed with
         * data:${mediatype};base64,${actualBase64String}
         *
         * For example data:image/png;base64, iVBORw0KGgoAAAANSUhEUg.........
         * We're only interested in the media type and the actual base 64 string.
         * The following split with regex returns the media type (index 1) and
         * base64 string (index 3)
         */
        const splitString = base64WithPrefix.split(/[:;,]/)
        const mediaType = splitString[1]
        const base64String = splitString[3]

        const byteCharacters = atob(base64String)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const imageBlob = new Blob([new Uint8Array(byteNumbers)], {
            type: mediaType,
        })

        return this.submitFile(imageBlob)
    }

    /**
     *
     * @param {string} imageUrl URL of image
     * @returns {Promise<Object>} Promise of request to the upload server
     */
    sendImageFromInternetToUploadServer(imageUrl) {
        return $.ajax({
            url: imageUrl,
            xhrFields: {
                responseType: 'blob',
            },
            dataType: 'binary',
            success: (imageBlob) => {
                this.submitFile(imageBlob)
            },
            error: () => {
                this.set({status: 'error'})
            },
            global: false,
        })
    }

    // Takes a file id and a hash and saves it through the UploadModel without actual uploading.
    async addFileThroughExistingFileId(answer) {

        /**
         * match and capture the value starting with 'tham.prod.' until / to detect if
         * inserted string is a tham id. if so, create an edu file
         *
         * https://secure.tham.thiememeulenhoff.nl/image-output/tham.prod.a943494f-5c0f-4ead-98c4-ddee8c7bad27/morestuff
         * tham.prod.a943494f-5c0f-4ead-98c4-ddee8c7bad27
         */
        const thamIdMatch = answer.match(/(tham\.prod\.[^\/\s]+)/)

        if (thamIdMatch) {
            const thamId = thamIdMatch[1]
            const eduFileId = await $.post('/edu_files/add_tham_id.json', [thamId])

            answer = eduFileId[thamId]

        } else if (isNaN(parseInt(answer))) {
            // if not thieme tham url, check if given string is numeric
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Not a valid edu file id or thieme url'),
                'error',
            )
            return
        }

        // check if a valid file_id has been added
        if (answer) {

            // simulate an upload, since the code that is using this UploadModel
            // expects a regular upload
            this.set({
                status: 'loading',
                progress: 0
            });

            // We expect input of an edu file id and a hash
            // f.e.: 34/b3ed891f936e805fb2055f8d2352bac3
            const fileAndHash = answer.split('/');
            const fileId = fileAndHash[0]
            let hash = fileAndHash[1]

            // If no hash provided, ask the backend to generate one
            if (!hash) {
                hash = await $.get('/edu_files/get_hash/' + fileId + '.json')
            }

            setTimeout(() => {

                const eduFileUrl = `/edu_files/open/${fileId}/${hash}`;

                // set the fileId, so that a change event is triggered
                // and the code that is using this UploadModel thinks an
                // upload is finished
                this.set({
                    status: 'success',
                    progress: 100,
                    filesId: fileId,
                    hash,
                    url: eduFileUrl,
                    eduFileUrl
                });
            }, 1);
        }
    }

}
