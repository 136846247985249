import TimeUtil from 'util/TimeUtil'

export default Backbone.Model.extend({

    initialize() {

        // Only set detected on when it is not already set. We do this because localstorage could
        // reconstruct the model at a later stage
        if (!this.get('detected_on')) {
            this.set('detected_on', TimeUtil.getDatabaseCompatibleTimestamp())
        }
    },

    /**
     * parse
     *
     * When receiving exam reports from the server, parse them to make them easier
     * to sort by the teacher.
     *
     * @param  {Object} response    raw response data object
     * @return {Object}             parsed object
     */
    parse(response) {

        // Replace user_id with StudentModel with the same ID.
        this.set({
            student: Backbone.Collection.students.get(response.user_id)
        });
        delete response.user_id;

        return response;

    },

    /**
         * save
         *
         * Save model to localStorage and try to sync it to the backend.
         */
    save() {

        // Create localStorage model with the current model type and model data.
        // Remember the localStorage model ID so it can be removed from
        // localStorage once the current model has been successfully synced to
        // the backend.
        var localStorageTypeModel = Backbone.Collection.localStorage.add({
            modelType: 'ExamReportModel',
            modelData: this.attributes
        });
        this.localStorageModelID = localStorageTypeModel.id;

        this.sync();

    },

    /**
         * sync
         *
         * Sync exam report type and info to the backend. Remove the copy of the data
         * in localStorage if POST request succeeds.
         */
    sync() {
        $.post({
            // Fix for: LB10427 where the student blocks the URL for exam reports.
            // therefore we've renamed the endpoint to be the same as the one for
            // responses, making it impossible to block.
            // The backend checks for the key: isExamReport to determine the
            // difference between a report and response.
            url: '/responses/exam_add.json',
            timeout: 10000,
            data: {
                isExamReport: true,
                activity_id: this.get('activity_id'),
                type: this.get('type'),
                info: this.get('info'),
                detected_on: this.get('detected_on'),
            },
            success: () => {
                Backbone.Collection.localStorage.onSyncSuccess(this.localStorageModelID)
            },
            error: (response) => {
                Backbone.Collection.localStorage.onSyncError(response.status, this.localStorageModelID)
            }
        });
    }
}, {
    type: 'examReport'
});
