<script>
    import Icon from '../icon/Icon.svelte';

    export let isInsideButton = false
    export let hasModalParent = false
</script>

<span class="spinner" class:is-inside-button={isInsideButton} class:has-modal-parent={hasModalParent}>
    <Icon name="spinner"></Icon>
</span>

<style lang="scss">
    .spinner {
        width: 100%;
        height: 100%;
        max-width: 160px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes dash {
            0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
            }

            50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35px;
            }

            100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124px;
            }
        }

        :global(svg) {
            animation: rotate 2s linear infinite;
            transform-origin: center;

            :global(circle) {
                stroke: currentColor;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                animation: dash 1.5s ease-in-out infinite;
                stroke-linecap: round;
                stroke-width: 2px;
            }
        }

        &:not(.is-inside-button) :global(svg) {
            width: 100%;
            height: 100%;
        }

        &.has-modal-parent {
            width: 100%;
            height: 150px;

            :global(svg) {
                width: 48px;
                height: 48px;
                color: rgba($blue, 0.5);
            }
        }
    }
</style>
