import Styles from './Sidebar.scss';

import Template from './Sidebar.hbs';
export default class Sidebar extends BaseView {

    // TODO refactor this mess

    initialize() {

        this.isOpen = false;

        _.bindAll(
            this,
            'showSidebar',
            'closeSidebar',
            'clickOverlay'
        );

        this.setElement(Template({
            Styles
        }))

        this.el.querySelector('.js-close-button').addEventListener('click', () => {
            this.closeSidebar()
        })

    }

    contrainWidth(width) {
        // Should reflex min-width and max-width pixel values in Sidebar.scss under .sidebar
        return Math.min(Math.max(width, 280), 820)
    }

    // TODO rename to open, like Modal
    showSidebar(sidebarContent, title, width, callback, showNextToContent = false, canBeClosedThroughOverlay = true) {

        // Close the current sidebar (if it exists)
        if (this.view) {
            this.closeSidebar();
        }

        this.view = sidebarContent

        width = this.contrainWidth(width)

        this.showNextToContent = showNextToContent

        // If a title is given as argument
        if (title !== null && typeof title === 'object') {
            this.$('.js-sidebar-label').text(title.label);
            this.$('.js-sidebar-header').text(title.title);
            this.$('.js-sidebar-label').css('display', 'inline-flex');
        } else {
            this.$('.js-sidebar-header').text(title || '')
            this.$('.js-sidebar-label').css('display', 'none');
        }

        if (!canBeClosedThroughOverlay) {
            this.$('.js-sidebar-overlay').addClass('js-dont-close-sidebar')
        }

        // Add content to the sidebar
        this.$('.js-inner').html(sidebarContent.$el);

        if (ISMOBILE) {
            // On mobile, prevent scrolling when sidebar is opened
            this.el.setAttribute('data-disable-scrolling', '')
        } else {
            // On desktop, allow scrolling, but only show the scrollbar of the sidebar
            document.documentElement.classList.add('hide-scrollbar')
        }

        // If width is given
        if (width !== undefined) {

            // Set width of sidebar to given width
            this.$('.js-sidebar').width(width);
        } else {

            // Set it to width of the element
            width = this.$('.js-sidebar').width();
        }

        // Add class to make sidebar visible
        this.$el.addClass(Styles['sidebar-open'])

        if (sidebarContent.show !== undefined && typeof sidebarContent.show === 'function') {
            sidebarContent.show();
        }

        if (!showNextToContent) {
            // Add click listeners to close the sidebar
            this.$('.js-sidebar-overlay').on('click', this.clickOverlay);

        } else {
            document.body.classList.add('has-fixed-sidebar')
            TweenMax.to(document.body, {
                marginRight: width,
                duration: 0.25,
                ease: 'power1.out',
                onComplete() {
                    window.dispatchEvent(new Event('resize'))
                }
            })
        }

        this.isOpen = true;

        // Trigger event for other views to listen to when they need to do something when the sidebar has opened
        this.trigger('sidebarIsOpen');

        window.statsTracker.trackEvent(
            window.app.controller.activePath.join('/'),
            'Sidebar opened: ' + title
        );

    }

    clickOverlay(e) {
        // Check if target of click isn't the sidebar nor a descendant of the sidebar
        // Or is the event target has the js-dont-close-sidebar class
        if (
            !this.$('.js-sidebar,.js-dont-close-sidebar').is(e.target) &&
            this.$('.js-sidebar,.js-dont-close-sidebar').has(e.target).length === 0
        ) {
            this.closeSidebar();
        }
    }

    // TODO rename to close, like Modal
    closeSidebar(callback) {

        delete this.view

        // Remove close click listener from the overlay
        this.$('.js-sidebar-overlay').off('click', this.clickOverlay);

        this.$('.js-sidebar-overlay').removeClass('js-dont-close-sidebar')

        // Return to normal scroll behavior
        if (ISMOBILE) {
            this.el.removeAttribute('data-disable-scrolling')
        } else {
            document.documentElement.classList.remove('hide-scrollbar')
        }

        // Remove class to make sidebar invisible
        this.$el.removeClass(Styles['sidebar-open'])

        this.$('.js-sidebar-label').css('display', 'none');

        // Detach the backbutton from the DOM for moving
        var backbuttonElement = this.$('.js-sidebar-backnav-button');

        // Hide backbutton
        backbuttonElement.css('display', 'none');

        this.isOpen = false;

        if (typeof callback === 'function') {
            callback();
        }
        this.trigger('sidebarClosed');

        if (this.showNextToContent) {
            document.body.classList.remove('has-fixed-sidebar')
            delete this.showNextToContent
            TweenMax.to(document.body, {
                marginRight: 0,
                duration: 0.25,
                ease: 'power1.out',
                onComplete() {
                    window.dispatchEvent(new Event('resize'))
                }
            })
        }

        return false;
    }

}
