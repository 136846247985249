import ElementModel from 'models/ElementModel'
import Util from 'util/util'

export default class SourceModel extends ElementModel {

    get hasValidTemplateId() {
        return SourceModel.validTemplateIds.includes(this.get('template_id'))
    }

    get defaults() {
        return {
            element_type: 'source',
            mergedSourceIds: this.id ? [ this.id ] : [],
        }
    }

    static get validTemplateIds() {
        return [
            1, 2, 3, 4, 6, 7, 12, 13, 14, 15, 35,
        ]
    }

    preinitialize() {
        this.constructor.type = 'source'
        this.constructor.pluralType = 'sources'
    }

    url() {
        if (this.id) {
            // eslint-disable-next-line prefer-template
            return '/sources/backbone_backend/' + this.get('task_group_id') + '/' + this.id + '.json';
        }
        return '/sources/backbone_backend.json';
    }

    initEmptyState() {
        this.set({
            Subject: {subject: undefined, id: 0},
            QuestionType: {question_type: '...', id: 0},
            title: '',
            source: this.getInitialStateData(),
        })
    }

    getInitialStateData() {
        return SourceModel.getInitialStateData(this.get('template_id'))
    }

    static getInitialStateData(templateId) {
        switch (templateId) {
            case 1: return {images: [], columns: 1}
            case 2: return ''
            case 3: return {fileId: 0, fileIdHash: ''}
            case 6: return {text: [], reference: '', subtitle: ''}
            case 12: return {options: {template: 'default'}}
            case 13: return {hash: '', type: 'youtube'}
            case 14: return []
            case 15: return {code: '', language: 'python'}
            default:
                return {}
        }
    }

    // Create clone of this SourceModel for use in the author, allowing the user to make changes without effecting
    // the original untill the changes are saved to the backend.
    getEditableClone() {
        return new SourceModel({
            ...this.attributes,
            source: _.clone(this.get('source'))
        })
    }

    getShortText(limitLength = false) {

        // If the source has a title, use that as the short text
        const title = Util.stripTags(this.get('title')?.trim()) || ''
        if (title) {
            return (limitLength && title.length > 75) ? title.slice(0, 75) + '…' : title
        }

        if (_.isEmpty(this.get('source'))) {
            return ''
        }

        let contentTitle = ''

        switch (this.get('template_id')) {

            // Images source has caption as fallback
            case 1:
                contentTitle = Util.stripTags(this.get('source').images?.find(({caption}) => caption) || this.get('source').caption) || ''
                break

            // Text with line numbers has subtitle as fallback
            case 6:
                contentTitle = Util.stripTags(this.get('source').subtitle) || ''
                break

            // Text has text content as fallback
            case 12:
                contentTitle = Util.stripTags(this.get('source').text) || ''
                break

            // Other types have no fallback
            default:
                contentTitle = ''
        }

        if (limitLength && contentTitle.length > 75) {
            return contentTitle.slice(0, 75) + '…'
        }

        return contentTitle
    }

    getElementName() {
        return SourceModel.getElementName(this.get('template_id'))
    }

    static getElementName(templateId) {
        switch (templateId) {
            case 1: return window.i18n.gettext('Images');
            case 2: return window.i18n.gettext('Embed code');
            case 3: return window.i18n.gettext('File');
            case 4: return window.i18n.gettext('Link');
            case 6: return window.i18n.gettext('Text with line numbers');
            case 7: return window.i18n.gettext('Audio');
            case 12: return window.i18n.gettext('Text');
            case 13: return window.i18n.gettext('Video');
            case 14: return window.i18n.gettext('Answer to previous question');
            case 15: return window.i18n.gettext('Code');
            case 35: return window.i18n.gettext('Algebrakit source');
        }
    }

    getElementIcon() {
        return SourceModel.getElementIcon(this.get('template_id'))
    }

    static getElementIcon(templateId) {
        switch (templateId) {
            case 1: return 'source-1';
            case 2: return 'source-2';
            case 3: return 'source-3';
            case 4: return 'source-4';
            case 6: return 'source-6';
            case 7: return 'source-7';
            case 12: return 'source-12';
            case 13: return 'source-13';
            case 14: return 'source-14';
            case 15: return 'code-braces';
            case 35: return 'task-35';
        }
    }

    getColors() {
        return SourceModel.getColors(this.get('template_id'))
    }

    static getColors(templateId) {
        switch (templateId) {
            case 12:
                return [
                    { keyword: 'blue', rgb: '45, 110, 157', label: window.i18n.gettext('Blue') },
                    { keyword: 'green', rgb: '107, 155, 32', label: window.i18n.gettext('Green') },
                    { keyword: 'orange', rgb: '206, 84, 58', label: window.i18n.gettext('Orange') },
                    { keyword: 'pink', rgb: '193, 46, 106', label: window.i18n.gettext('Pink') },
                    { keyword: 'purple', rgb: '108, 66, 162', label: window.i18n.gettext('Purple') }
                ]
        }
    }

    getFonts() {
        return SourceModel.getFonts(this.get('template_id'))
    }

    static getFonts(templateId) {
        switch (templateId) {
            case 12:
                return [
                    { keyword: 'default', family: 'effra', label: window.i18n.gettext('Default') },
                    { keyword: 'chique', family: 'ff-tisa-web-pro', label: window.i18n.gettext('Chique') },
                    { keyword: 'handwritten', family: 'verveine', label: window.i18n.gettext('Handwritten') },
                    { keyword: 'informative', family: 'alternate-gothic-no-2-d', label: window.i18n.gettext('Informative') },
                    { keyword: 'modern', family: 'camingodos-web', label: window.i18n.gettext('Modern') },
                    { keyword: 'playful', family: 'bree', label: window.i18n.gettext('Playful') }
                ]
        }
    }

    async save_element(attributes) {
        Object.assign(attributes, {
            type: this.get('type'),
            in_theory_collection: (
                this.metaData?.inTheoryCollectionInput && this.metaData.inTheoryCollectionInput.getState()
            ) | 0
        })

        await this.save(attributes, {
            patch: true,
            wait: true,
        })
    }

}
