<script>
    export let inline = false;
    export let callback = () => {};
    export let isChecked = false;
    export let isDisabled = false;
    export let label = '';
    export let parentGroup;
    export let rightAlignedText = null;
    export let subText = null;
    export let tooltip = null;
    export let value;

    const cid = _.uniqueId();

    function handleChange(event) {
        if (callback) {
            callback(parseInt(event.target.value));
        }
    }
</script>

<label
    id={cid}
    class="radiobtn"
    class:radiobtn--inline={inline}
    title={tooltip}
>
    <input
        type="radio"
        name={parentGroup}
        {value}
        on:change={handleChange}
        disabled={isDisabled}
        checked={isChecked}
    />
    <span class="radiobtn__dot"><div></div></span>
    <span class="label-container">
        <span class="label">
            <slot name="label">
                {label}
            </slot>
        </span>
        {#if subText}
            <span class="sub-text">{subText}</span>
        {/if}
    </span>
    <slot name="right">
        {#if rightAlignedText}
            <span class="right-aligned-text">{rightAlignedText}</span>
        {/if}
    </slot>
</label>

<style lang="scss">

    .radiobtn {
        position: relative;
        display: flex;
        align-items: center;

        cursor: pointer;

        &--inline {
            display: inline-flex;
            margin-right: $spacing-16;
            min-height: $spacing-32;
        }

        &__dot {
            display: flex;
            justify-content: center;
            align-items: center;

            width: $spacing-24;
            min-width: $spacing-24;
            height: $spacing-24;

            margin-right: $spacing-16;
            background: $white;

            border: 1px solid $status-gray;

            border-radius: 50%;

            > div {
                --size: 10px;

                width: var(--size);
                height: var(--size);
                border-radius: inherit;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
            cursor: pointer;

            &:disabled ~ * {
                opacity: 0.4;
                cursor: not-allowed;
            }

            &:checked {
                ~ .radiobtn__dot {
                    background-color: $blue;
                    border-color: $blue;

                    > div {
                        background-color: $white;
                    }
                }
            }

            &:focus {
                ~ .radiobtn__dot {
                    @include box-shadow-focus;
                }
            }
        }
    }

    .label-container {
        flex-grow: 1;
    }

    .label {
        display: block;
    }

    .sub-text {
        display: block;
        margin-top: 2px;
        color: $status-gray;
    }

    .right-aligned-text {
        color: $status-gray;
        text-align: right;
        margin-left: $spacing-8;
    }
</style>
