import Styles from './Source3.scss';

import Template from './Source3.hbs';
// Download file source view
export default BaseView.extend({

    initialize() {
        const fileId = this.model.get('source')?.fileId
        const cloudStorageUrl = this.model.get('source')?.cloudStorageUrl

        this.setElement(Template({
            Styles,
            hasFile: fileId || cloudStorageUrl,
            fileId,
            fileIdHash: this.model.get('source')?.fileIdHash,
            cloudStorageUrl,
            title: this.model.get('title')
        }));

        // For these Noordhoff taskgroups, track the amount of download clicks for files
        // This can be removed when the analysis is done, added december 2024
        const noordhoffTaskgroups = [2257466, 2257520, 2257524, 2258589, 2260147, 2262130, 2262680, 3202162, 2263710,
            2265675, 2265694, 2265717, 2267034, 2267545, 2309938, 2278488, 2285585, 2285646, 2285777, 2285778, 2285779,
            2285782, 2286665, 2286667, 2288940, 2288943, 2288944, 2289154, 2289156, 2289157, 2289158, 2289160, 2306274,
            2306275, 2309667, 2306167, 2306262, 2307441, 2307442, 2307443, 2307699, 2307740, 2310171, 2310202, 2310220,
            2310272, 2310522, 2310525, 2310554, 2317199, 2317229, 2317256, 2317316, 2317325, 2317328, 2317542, 2317437,
            2317470, 2317541, 2317568, 2317580, 2351396, 2351397, 2351398, 2351753, 2351755, 2351764, 2361010, 2361013,
            2361014, 2361015, 2362825, 2362826, 2362828, 2362829, 2362893, 2362894, 2362895, 2363524, 2363527, 2363528,
            2363529, 2363731, 2363742, 2364012, 2364013, 2364014, 2364203, 2364204, 2364205, 2364206, 2364209, 2365080,
            2365081, 2366592, 2360540, 2360542, 2360543, 2360546, 2360549, 2360604, 2360606, 2360969, 2360970, 2360973,
            2366927, 2366928, 2366929, 2369220, 2369222, 2369224, 2369226]
        if (noordhoffTaskgroups.includes(this.model.get('task_group_id'))) {
            this.$('a[href]').on('click', () => {
                window.statsTracker.trackEvent(
                    window.app.controller.activePath.join('/'),
                    'Download Noordhoff file',
                    this.model.get('task_group_id')
                )
            })
        }

    }
});
