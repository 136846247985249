import Styles from './TextToSpeech.scss';

import Template from './TextToSpeech.hbs';
import TextToSpeech from 'views/components/textToSpeech/TextToSpeech'
import Button from 'views/components/button/Button'
import AudioPlayer from 'views/components/audioPlayer/AudioPlayer'

export default class TextToSpeechSource extends TextToSpeech {

    get maxAttempts() {
        return 16
    }

    initialize({
        sourceId,
        audioPlayerTargetElement,
        showLabel = false,
    }) {

        this.setElement(Template({
            Styles
        }))

        let audioPlayer
        const speechButton = this.addChildView(new Button({
            icon: 'volume-up',
            title: showLabel ? '' : window.i18n.gettext('Speech'),
            label: showLabel ? window.i18n.gettext('Speech') : '',
            inline: true,
            theme: 'secondary',
            callback: () => {
                speechButton.disable(true)
                audioPlayerTargetElement?.hide()
                if (!audioPlayer) {
                    audioPlayer = this.addChildView(new AudioPlayer({
                        autoPlay: true,
                        playbackRate: 0.95,
                    }), audioPlayerTargetElement || this.$el)
                }
                this.fetchAudio(sourceId, audioPlayer, audioPlayerTargetElement, speechButton)
                window.statsTracker.trackEvent(
                    window.app.controller.activePath.join('/'),
                    'Text to speech started'
                )
            },
        }), this.$el)

    }

    async fetchAudio(sourceId, audioPlayer, audioPlayerTargetElement, speechButton, attempt = 0) {
        attempt++

        if (Array.isArray(sourceId) && sourceId.length === 1) {
            sourceId = sourceId[0]
        }

        let fetchPromise
        if (Array.isArray(sourceId)) {
            fetchPromise = Promise.all(
                sourceId.map((id) => {
                    return super.fetchAudio(`/sources/synthesize/${id}.json`, true)
                })
            ).then((blobs) => {
                Backbone.View.layout.closeStatus()
                speechButton.destroy()

                const compoundBlob = new Blob([...blobs], {type: 'audio/mp3'})
                audioPlayer.mediaSource = compoundBlob
                audioPlayerTargetElement?.show()
            })
        } else {
            fetchPromise = super.fetchAudio(`/sources/synthesize/${sourceId}.json`, true).then((blob) => {
                Backbone.View.layout.closeStatus()
                speechButton.destroy()
                audioPlayer.mediaSource = blob
                audioPlayerTargetElement?.show()
            })
        }
        fetchPromise.catch((error) => {
            if (error.error_code === 35202) {
                if (attempt > this.maxAttempts) {
                    speechButton.enable()
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('The audio could not be downloaded. Try again later.')
                    )
                } else {
                    setTimeout(() => {
                        this.fetchAudio(sourceId, audioPlayer, audioPlayerTargetElement, speechButton, attempt)
                    }, 500)
                    if (attempt === 4) {
                        Backbone.View.layout.openStatus(error.displayMessage, 'loading', {noHide: true})
                    }
                }
            } else {
                if (error.displayMessage) {
                    Backbone.View.layout.openStatus(error.displayMessage)
                } else {
                    Backbone.View.layout.closeStatus()
                }
                speechButton.enable()
            }
            console.warn(error)
        })
    }

}
