<script>
    import NotesCollection from 'collections/NotesCollection'
    import Spinner from 'views/components/spinner/Spinner.svelte'
    import Note from './Note.svelte';
    import StatusCard from 'views/components/statusCard/StatusCard.svelte';

    Backbone.View.header.clearButtons()
    Backbone.View.header.setTitle(window.i18n.gettext('Feedback'))
    Backbone.View.header.setCrumblepath()

    const notes = new NotesCollection()
    const request = notes.fetch({
        success: () => {
            Backbone.View.menubar.setNewNotes(0)
        },
    })

    function getEmptyText() {
        if (Backbone.Model.user.get('is_student')) {
            return window.i18n.gettext('You have not received any feedback yet.')
        }

        return window.i18n.gettext('You have not sent any feedback yet.')
    }
</script>

<div class="center-panel">
    {#await request}
        <div class="spinner">
            <Spinner></Spinner>
        </div>
    {:then}
        <div class="list">
            {#each notes.models as model (model.id)}
                <Note {model}></Note>
            {:else}
                <StatusCard icon="info-circle">
                    {getEmptyText()}
                </StatusCard>
            {/each}
        </div>
    {/await}
</div>

<style lang="scss">
    .spinner {
        margin: 48px 0;
        color: $line-gray;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 48px;
    }
</style>
