<script>
    import StatusCard from 'views/components/statusCard/StatusCard.svelte'
    import {onDestroy} from 'svelte'
    import Lightbox from 'views/components/lightbox/Lightbox.svelte';
    import Util from 'util/util'
    import TextToSpeechSource from 'views/components/taskGroups/sources/textToSpeech/TextToSpeech';

    export let title = ''
    export let images = []
    export let columns = 1
    export let canZoom = true
    export let singleImageSize = null
    export let sourceId = null

    const minImageWidth = 200

    let lightbox
    let containerElement
    let containerWidth
    let resizeObserver
    let displayedColumns = columns
    updateContainerWidth()

    // Calc width of container element to set a CSS variable to set the best looking
    // maximum height for images.
    function updateContainerWidth() {
        window.requestAnimationFrame(() => {
            if (containerElement) {
                containerWidth = containerElement.clientWidth
                displayedColumns = containerWidth < (minImageWidth * columns) ?
                    Math.floor(containerWidth / minImageWidth) : columns
            }
        })
    }

    function addResizeObserver(el) {
        resizeObserver = new ResizeObserver(updateContainerWidth)
        resizeObserver.observe(el)
    }

    function openLightbox(image) {
        if (lightbox) {
            lightbox.openAtSlide = image.url
            lightbox.isOpen = true
        } else {
            lightbox = new Lightbox({
                target: document.body,
                props: {
                    slides: images.map(({url, caption}) => {
                        return {
                            url: '/edu_files/open/' + url,
                            caption,
                        }
                    }),
                    openAtSlide: '/edu_files/open/' + image.url,
                }
            })
        }
    }

    function addSpeechPlayer(el) {
        const textToSpeechView = new TextToSpeechSource({
            sourceId,
            audioPlayerTargetElement: jQuery(el),
            showLabel: true,
        })
        textToSpeechView.appendTo(el)
    }

    onDestroy(() => {
        resizeObserver.disconnect()
        lightbox?.$destroy()
    })
</script>

<!-- eslint-disable-next-line svelte/no-at-html-tags -->
<header>{@html Util.renderContentSafely(title)}</header>
<div class="grid-container" bind:this={containerElement} use:addResizeObserver>
    <div
        class="grid"
        style:--number-of-columns={displayedColumns}
        style:--container-width={containerWidth + 'px'}
        style:--min-image-width={minImageWidth + 'px'}
    >
        {#each images as image (image.url)}
            <figure>
                {#if image.speechCaption}
                    <div class="speech-button" use:addSpeechPlayer></div>
                {/if}
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <svelte:element
                    this={canZoom ? 'button' : 'div'}
                    class="image-container"
                    class:can-zoom={canZoom}
                    on:click={canZoom ? openLightbox(image) : null}
                >
                    <img
                        src="/edu_files/open/{image.url}"
                        alt={image.caption}
                        class:large={images.length === 1 && singleImageSize === 'large'}
                        class:medium={images.length === 1 && singleImageSize === 'medium'}
                        class:small={images.length === 1 && singleImageSize === 'small'}
                        class:full-width={images.length === 1 && singleImageSize === null}
                    />
                </svelte:element>
                {#if image.caption}
                    <figcaption>
                        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                        {@html Util.renderContentSafely(Util.nl2br(image.caption))}
                        {#if image.source}
                            <cite data-source-prefix="{window.i18n.gettext('Source')}: ">{image.source}</cite>
                        {/if}
                    </figcaption>
                {/if}
            </figure>
        {:else}
            <StatusCard icon="warning" color="orange">{window.i18n.gettext('Can\'t find an image related to this source.')}</StatusCard>
        {/each}
    </div>
</div>

<style lang="scss">
    header {
        @include large-title-text;
        padding: 16px 0;
        line-height: 1.2;
        margin: 0 auto;
        word-wrap: break-word;
        hyphens: auto;
        text-align: center;

        @media (max-width: 620px) {
            @include small-title-text;
            padding: 12px 0;
        }

        &:empty {
            display: none;
        }
    }

    .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(var(--number-of-columns), minmax(0, 1fr));
        gap: max(8px, 20px - var(--number-of-columns) * 4px);
    }

    figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: clip;
        border-radius: 12px;
        outline: 1px solid $line-gray;
        transition: outline 0.2s;

        &:focus-within {
            @include box-shadow-focus;

            button:focus {
                outline: none;
            }
        }

        &:has(.image-container.can-zoom):hover {
            outline: 1px solid $black-25;
        }
    }

    .speech-button {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 12px;
        background-color: $white;
        width: 100%;
    }

    .image-container {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: $white-highlight;
        transition: filter 0.2s;

        &.can-zoom {
            cursor: zoom-in;

            &:hover {
                filter: brightness(0.85) contrast(1.15);
            }
        }
    }

    img {
        display: block;

        // This is needed to prevent SVG's without a width from disappearing
        min-width: var(--min-image-width);

        max-width: 100%;
        max-height: min(calc(var(--container-width) / var(--number-of-columns)), 300px);
        object-fit: scale-down;
        margin: auto;

        &.small {
            max-width: 320px;
            max-height: unset;
        }

        &.medium {
            max-width: 560px;
            max-height: unset;
        }

        &.large {
            max-width: 800px;
            max-height: unset;
        }

        &.full-width {
            max-height: unset;
        }
    }

    figcaption {
        @include normal-text;
        text-align: center;
        text-wrap: balance;
        width: 100%;
        padding: 12px;
        background-color: $white;

        cite {
            @include small-text;
            color: $blue-black-50;
            word-wrap: break-word;
            display: block;

            &::before {
                content: attr(data-source-prefix);
            }
        }
    }
</style>
