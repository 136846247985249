import Styles from './HeroButton.scss';

import Template from './HeroButton.hbs';
export default class HeroButton extends BaseView {

    // Events for this view
    get events() {
        return {
            click: 'onClickButton'
        }
    }

    /**
     * initialize
     *
     * @oaram {object} options Options object
     * @param {Function} options.callback Callback called when herobutton is clicked
     * @param {string} options.firstLine Mandatory text for herobutton
     * @param {string} [options.icon] string with svg name for icon
     * @param {string} [options.identifier] class name used as unique selector for js actions
     *
     */
    initialize({
        callback,
        firstLine,
        icon,
        showBadge,
        identifier,
        positionFromLeft = false,
        isDisabled = false
    }) {

        this.callback = callback;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            firstLine,
            icon,
            showBadge,
            hasCallback: this.isDropdownButton || this.callback instanceof Function,
            isDropdownButton: this.isDropdownButton,
            hasIdentifier: !!identifier,
            identifier,
            positionFromLeft,
            isDisabled
        }));
    }

    enable() {
        this.el.disabled = false
    }

    disable() {
        this.el.disabled = true
    }

    /**
     * onClickButton
     *
     * This function determines what the button should do when clicked.
     */
    onClickButton() {
        if (this.callback) {
            this.callback();
        }
    }

    /**
     * setBadge
     *
     * Show a number or string at the right side of the hero button.
     *
     * @param {string|number} badge     badge value
     */
    setBadge(badge) {
        this.$('.js-badge').text(badge);
    }

    /**
     * setLabel
     *
     * Overwrite the text label(s) of the hero button
     *
     * @param {string} firstLine    label of the first (or only) line
     */
    setLabel(firstLine) {
        this.$('.js-label span:nth-child(1)').text(firstLine)
    }

}
