<script>
    import Avatar from 'views/components/avatar/Avatar.svelte'
    import Icon from 'views/components/icon/Icon.svelte'
    import InlineCrumblepath from 'views/components/inlineCrumblepath/InlineCrumblepath'
    import GroupNote from 'views/components/notes/sidebar/GroupNote';
    import Util from '/util/util'
    import TimeUtil from '/util/TimeUtil'

    export let model

    let name, avatar, direction = ''

    if (Backbone.Model.user.get('is_student')) {

        // If this student replied to the message, it is an outgoing message
        direction = model.get('reply_user_id') === Backbone.Model.user.id ? 'out' : 'in'
        const teacher = Backbone.Collection.teachers.get(model.get('sender_id'))
        if (teacher) {
            name = teacher.first_name_last_name()
            avatar = teacher.get('avatar')
        }

    } else if (Backbone.Model.user.get('is_teacher')) {

        // If a student replied to the message, it is an incoming message
        if (model.get('reply_user_id')) {
            direction = 'in'
            const student = Backbone.Collection.students.get(model.get('reply_user_id'))
            if (student) {
                name = student.first_name_last_name()
                avatar = student.get('avatar')
            }
        } else {
            direction = 'out'
            if (model.get('recipient_user_id') === 0) {
                name = window.i18n.gettext('Whole class')
                avatar = 'group'
            } else {
                const student = Backbone.Collection.students.get(model.get('recipient_user_id'))
                if (student) {
                    name = student.first_name_last_name()
                    avatar = student.get('avatar')
                }
            }
        }
    }

    function addCrumblepath(element) {
        let crumblepathModel
        if (model.get('group_id')) {
            crumblepathModel = Backbone.Collection.groups.get(model.get('group_id'))
        } else {
            crumblepathModel = Backbone.Collection.activities.get(model.get('Activity').id)
        }

        if (!crumblepathModel) {
            window.sentry.withScope(scope => {
                scope.setExtra('feedback data', model)
                window.sentry.captureException('Feedback message without valid crumblepath')
            })
            return
        }

        element.append(
            new InlineCrumblepath({
                model: crumblepathModel,
                pageType: 'show'
            }).el
        )
    }

    function onClickItem() {
        if (model.get('group_id')) {
            const groupNote = new GroupNote({ model })
            Backbone.View.sidebar.showSidebar(groupNote, window.i18n.gettext('Feedback'), 630)
            return
        }

        const url = '/activities/show/' + model.get('Activity').id + '/task_id:' + model.get('task_id') + '/' + model.get('id')
        Backbone.history.navigate(url, { trigger: true })
    }
</script>

<section class="note">
    <button
        class="item"
        class:is-new={model.get('is_new')}
        on:click={onClickItem}>

        <div class="avatar">
            {#if avatar === 'group'}
                <Icon name="people"></Icon>
            {:else}
                <Avatar {avatar}></Avatar>
            {/if}
        </div>

        <div class="content">
            <div class="name">
                {#if direction === 'in'}
                    {window.i18n.gettext('From')} {name}
                {:else}
                    {window.i18n.gettext('To')} {name}
                {/if}
            </div>

            <div class="message" class:is-incoming={direction === 'in'}>
                {#if direction === 'in'}
                    <Icon name="messageFrom"></Icon>
                {:else}
                    <Icon name="messageTo"></Icon>
                {/if}
                <div>{Util.stripTags(model.get('reply_message') || model.get('message'))}</div>
            </div>
        </div>

        <div class="date">{TimeUtil.getAbsoluteDate(model.get('reply_created') || model.get('created'))}</div>

        <div class="arrow">
            <Icon name="arrow-right"></Icon>
        </div>
    </button>

    <nav use:addCrumblepath></nav>
</section>

<style lang="scss">
    .note {
        @include card;
        flex-direction: column;
        padding: unset;
        position: relative;
        text-decoration: none;

        .item {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $background-gray;
            padding: 16px;
            border-radius: 16px 16px 0 0;
            cursor: pointer;

            @media (max-width: 620px) {
                padding: 16px 12px;
            }

            &:hover {
                background-color: $white-highlight;
            }

            &.is-new {
                font-weight: 500;

                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    left: -10px;
                    width: 12px;
                    height: 12px;
                    background: $blue;
                    border: 4px solid $white;
                    border-radius: 50%;
                }
            }

            .avatar {
                display: flex;
                align-items: center;
                margin-right: 10px;

                :global(svg) {
                    width: 28px;
                    height: 28px;
                    padding: 4px;
                    fill: $blue-black-80;
                    background-color: $white;
                    border-radius: 50%;
                    border: 2px solid rgba($black, 0.1);
                }
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1;

                @media (max-width: 960px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .name {
                    flex-basis: 80%;
                    color: $blue-black;
                }

                .message {
                    display: flex;
                    align-items: center;
                    flex-basis: 100%;
                    max-height: 3.6rem;
                    overflow: hidden;
                    color: $blue-black-50;

                    &.is-incoming {
                        color: $black;
                    }

                    :global(svg) {
                        display: flex;
                        width: 20px;
                        height: 20px;
                        fill: currentColor;
                        margin-right: 8px;
                        flex-shrink: 0;

                        @media (max-width: 960px) {
                            display: none;
                        }
                    }
                }
            }

            .date {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-shrink: 0;
                width: 6rem;
                text-align: right;
                color: $blue-black-50;
            }

            .arrow {
                display: flex;

                :global(svg) {
                    height: 16px;
                    width: 16px;
                    margin-left: 8px;
                    fill: $blue-black;
                }
            }
        }

        nav {
            padding: 12px 16px;

            @media (max-width: 620px) {
                padding: 12px;
            }
        }
    }
</style>
