import LocalStorageModel from 'models/LocalStorageModel';

/**
 * LocalStorageCollection is for managing temporary data in window.localStorage in a
 * controlled and safe manner. By adding models to this collection, they will get
 * added to window.localStorage as well. When the inital sync fails due to being
 * offline or logged out, they will remain present in this collection until a
 * connection is reastablished. Since LocalStorageCollection supports multiple
 * types of models it can be used as a queuing system for syncing localStorage
 * content to the backend.
 *
 * Usage
 *
 * When implementing localStorage support for a new or existing Backbone Model, for
 * example ExampleModel, make sure to never call the .sync() method from outside
 * ExampleModel. Call the .save() method instead. See the example below:
 *
 *  save: function() {
 *
 *      // REQUIRED
 *      // Add model type and model data to global LocalStorageCollection
 *      Backbone.Collection.localStorage.add({
 *          // Has to be same name as the model file name
 *          modelType: 'ExampleModel',
 *          modelData: this.attributes
 *       });
 *
 *      // REQUIRED
 *      // call sync method to actually POST/PATCH to server
 *      this.sync();
 * },
 *
 *  sync: function() {
 *      $.post({
 *          url: '/examples/add/' + this.get('activity_id') + '.json',
 *          data: this.toJSON(),
 *          // REQUIRED
 *          // Removed model from localStorage when POST has been successful
 *          success: _.partial(Backbone.Collection.localStorage.onSyncSuccess, this.localStorageModelID)
 *     });
 *  }
 */

export default Backbone.Collection.extend({

    expirationLimitInDays: 30,

    model: LocalStorageModel,

    // Key prefix for setting, getting and removing data from window.localStorage.
    localStoragePrefix: 'learnbeat-local-storage-model-',

    initialize() {

        _.bindAll(
            this,
            'attemptSyncAll',
            'onSyncSuccess',
            'onSyncError'
        );

        // Find values in window.localStorage and add them to this collection.
        for (const key of Object.keys(window.localStorage)) {

            // Skip item if prefix does not match
            if (!key.startsWith(this.localStoragePrefix)) {
                continue
            }

            const dataObject = JSON.parse(window.localStorage.getItem(key))

            // If object has a valid client_time_created attribute, evaluate if this date if from before a time
            // this.expirationLimitInDays * millisecondsInADay from now. If so, remove this entry from
            // localStorage and skip to the next item.
            if (
                isFinite(dataObject.client_time_created) &&
                new Date() - new Date(dataObject.client_time_created) > 86400000 * this.expirationLimitInDays
            ) {
                window.localStorage.removeItem(key)
                continue
            }

            // Only add data that is created by the current user.
            if (dataObject.userID === Backbone.Model.user.id) {
                this.add(dataObject, {
                    silent: true
                })
            }
        }

    },

    /**
     * attemptSyncAll
     *
     * Attempt to sync all models to the backend, one at the time.
     */
    attemptSyncAll() {

        // If we are already syncing, do not start another one.
        // We use a 15 seconds limit to prevent somehow getting stuck in not saving.
        if (this.syncInProgress > (Date.now() - 15000)) {
            return
        }

        var firstModel = this.at(0);
        if (firstModel) {
            this.syncInProgress = Date.now()
            firstModel.attemptSync();
            // When a model is synced successfully, it gets removed from the
            // collection (see this.onSyncSuccess). Use this as the trigger
            // to sync the next model until there are no more left.
            this.once('remove', this.attemptSyncAll);
        }
    },

    /**
     * onSyncSuccess
     *
     * When sync with backend has been successfully completed by the typeModel,
     * remove the LocalStorageModel with this ID from this colllection and the
     * real localStorage as well.
     *
     * @param  {int|string} id  model uniqueID
     */
    onSyncSuccess(id) {
        delete this.syncInProgress
        this.remove(id);
        window.localStorage.removeItem(this.localStoragePrefix + id);
    },

    onSyncError(statusCode, id) {
        delete this.syncInProgress
        if ([400, 413].includes(statusCode)) {
            this.remove(id);
            window.localStorage.removeItem(this.localStoragePrefix + id);
        }
    }

}, {
    type: 'localStorage'
});
