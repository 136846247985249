<script>
    import Button from '../button/Button.svelte'
    import DropdownItem from './DropdownItem.svelte'

    // Array with DropdownItems for in the dropdown
    export let dropdownItems

    // Object with options (label, icon, etc) for the button that triggers the dropdown
    export let buttonOptions

    // Options for positioning the dropdown relative to the button
    export let positionFromLeft = false
    export let positionFromBottom = false

    // Whether the dropdown items are visible. Use this to close the dropdown.
    export let isOpen = false

    let containerElement

    function toggleDropdown() {
        isOpen = !isOpen
    }

    function closeDropdown() {
        isOpen = false
    }

    // Close the dropdown when clicking outside of it
    function onClickDocument(element) {
        if (!isOpen || containerElement.contains(element.target)) {
            return
        }

        isOpen = false
    }
</script>

<svelte:document on:click={onClickDocument} />

<div class="container" bind:this={containerElement}>
    <Button
        {...buttonOptions}
        callback={toggleDropdown}>
    </Button>

    <div
        class="list"
        class:from-left={positionFromLeft}
        class:from-bottom={positionFromBottom}
        class:is-open={isOpen}>

        {#each dropdownItems as dropdownItem}
            <DropdownItem {...dropdownItem} {closeDropdown}></DropdownItem>
        {/each}
    </div>
</div>

<style lang="scss">
    .container {
        position: relative;
    }

    .list {
        @include box-shadow;

        position: absolute;
        top: 100%;
        right: 0;
        z-index: 900;
        display: none;
        margin: 8px 0;
        padding: 16px;
        background: $white;
        border-radius: 12px;
        animation: open 0.25s ease-out;

        &.is-open {
            display: block;
        }

        &.from-left {
            right: unset;
            left: 0;
        }

        &.from-bottom {
            top: unset;
            bottom: 100%;
        }

        @keyframes open {
            0% { padding: 0; }
        }
    }
</style>
