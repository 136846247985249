<script>
    import Icon from '../icon/Icon.svelte';

    // Array with options to present: [ { label, icon, title, click: 'always' } ]
    // other keys can be added and will be returned in the callback
    export let choices
    export let fullWidth = false
    export let selectedIndex = 0
    export let callback = null
    export let doCallbackOnInitialize = false

    export function goToPrevious() {
        if (choices[previousIndex]) {
            changeToIndex(previousIndex)
        }
    }

    let previousIndex

    if (doCallbackOnInitialize && callback) {
        callback(choices[selectedIndex], selectedIndex)
    }

    function changeToIndex(index) {
        if (selectedIndex === index && choices[index].click !== 'always') {
            return
        }

        previousIndex = selectedIndex
        selectedIndex = index

        if (callback) {
            callback(choices[selectedIndex], selectedIndex)
        }
    }
</script>

<div class="group" class:full-width={fullWidth}>
    {#each choices as choice, index}
        <button
            class="item"
            class:active={index === selectedIndex}
            title={choice.title}
            on:click={() => changeToIndex(index)}>

            {#if choice.icon}
                <Icon name={choice.icon}></Icon>
            {/if}
            {#if choice.label}
                {choice.label}
            {/if}
            {#if choice.click === 'always'}
                <Icon name="arrow-right"></Icon>
            {/if}

        </button>
    {/each}
</div>

<style lang="scss">
    .group {
        display: inline-flex;
        overflow: hidden;
        flex-wrap: wrap;
        background: $white;
        border: 1px solid $line-gray;
        border-radius: $input-border-radius;
        color: $blue-black;

        &.full-width {
            display: flex;
            flex-wrap: nowrap;

            .item {
                flex-basis: 100%;
            }
        }

        .item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px 12px;
            border-radius: inherit;
            background-color: transparent;
            user-select: none;
            transition: background-color 0.2s $ease-out-cubic;

            &:hover:not(.active) {
                cursor: pointer;
                @include selectable-item-hover-state;
            }

            &.active {
                @include selectable-item-checked-state;
            }

            :global(svg) {
                display: flex;
                width: $spacing-16;
                height: $spacing-16;
                fill: currentColor;
            }
        }
    }
</style>
