import Styles from './Wysiwyg.scss';

import Template from './Wysiwyg.hbs';
import Toolbar from 'views/components/wysiwyg/parts/toolbar/Toolbar';
import ImageTemplate from 'views/components/wysiwyg/elements/image.hbs';
import URLTemplate from 'views/components/wysiwyg/elements/url.hbs'
import UploadImageModal from 'views/components/wysiwyg/modals/uploadImage/UploadImage';
import Spinner from 'views/components/spinner/Spinner';
import UploadModel from 'models/UploadModel';
import PastedImageUploader from 'util/PastedImageUploader';
import Button from 'views/components/button/Button';
import Util from 'util/util'

/**
 * Wysiwyg (What You See Is What You Get) is a text editor with markup built on top of the TinyMCE
 * (https://www.tiny.cloud) library. This library provides a complete solution, of which we only use a
 * subset of features. We don't use TinyMCE's toolbar and other UI components. Instead we built our own
 * toolbar (src/views/components/wysiwyg/parts/toolbar/Toolbar.js) which is populated by our own buttons
 * (src/views/components/wysiwyg/parts/buttons/), which interact with the TinyMCE API.
 */
export default class Wysiwyg extends BaseView {

    get editor() {
        const editor = window.tinyMCE?.get(this.TinymceId)
        return editor
    }

    get editorAllowsImages() {
        return this.toolbar.getButtons().hasOwnProperty('image')
    }

    get editorAllowsExpressions() {
        return this.toolbar.getButtons().hasOwnProperty('expression')
    }

    get isEditorDisabled() {
        if (this.editor) {
            return this.editor.readonly
        }
        return this.el.querySelector(`textarea#${this.TinymceId}`).disabled
    }

    initialize(options) {

        _.bindAll(
            this,
            'clearInput',
            'getContent',
            'initTinyMce',
            'loadTinyMCECustomElements',
            'onFailedLoadTinyMce',
            'onLoadTinyMce',
            'onMceCommand',
            'pastePostProcess',
            'setFocus',
            'setupTinyMCE',
            'onChangeImageUploadStatus'
        );

        _.extend(this, options);

        this.TinymceId = `tinyEditor${this.cid}`;

        this.setElement(Template({
            content: this.content,
            id: this.TinymceId,
            Styles
        }));

        // Create a toolbar element
        this.toolbar = this.addChildView(new Toolbar({

            // Pass the WysiwygView to it
            wysiwyg: this
        }), '.js-toolbar')

        this.initTinyMce()

    }

    setFocus() {
        // Only do this when the device is not touch
        if (!('ontouchend' in document)) {
            // Set focus on this tinyMCE
            this.editor.focus();
        }
    }

    clearInput() {
        // Set the content to ''
        this.editor.setContent('');
    }

    disableEditor() {
        if (this.editor) {
            this.editor.mode.set('readonly')
        } else {
            this.el.querySelector(`textarea#${this.TinymceId}`).disabled = true
        }
        this.$('.js-overlay').css('display', 'block');
    }

    enableEditor() {
        this.el.querySelector(`textarea#${this.TinymceId}`).disabled = false
        if (this.editor) {
            this.editor.mode.set('design')
        }
        this.$('.js-overlay').css('display', 'none');
    }

    getContent() {

        // If editor does not yet exists, abort. This might happen when the getContent method is called too quickly
        // after the Wysiwyg is initialized. If getting the right content is essential, wait for the 'editorLoaded'
        // event to trigger once or defer calling of getContent. Note that on abort, undefined is returned instead
        // of the editor content that might be expected.
        if (!this.editor) {
            return
        }

        // Check if the editor is visible
        if (this.editor.isHidden()) {

            // select the sourcebutton from the buttonList
            const sourceButton = this.toolbar.getButtons().source

            // Check if the source button's state is active
            if (sourceButton.isActive) {

                // Call the execMceCommand on sourcebutton to toggle the source
                this.onMceCommand(sourceButton);
            }
        }

        // Check if the editor is dirty
        // https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.editor/#isDirty
        if (this.editor.isDirty()) {

            // Call the save method
            // https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.editor/#save
            this.editor.save()
        }

        // Get the content from the editor
        let content = this.editor.getContent()

        // This function strips specific unwanted inline styling (font-size: 19.6px)
        // which is a bug triggered by tinyMCE.
        // The texteditor adds the styling attribute to different elements, hence they need to be sanitized
        // before saving to the DB.
        // TODO find the cause of this bug.
        content = content.replace(/ style="font-size: 19\.6px;"/g, '');

        // Return the editors content
        return content
    }

    /**
     * getElementSelected
     *
     * Return the Element that is currently inside the current selection or
     * underneath the current cursor position.
     *
     * @return {Element} root HTMLElement with subsequent child HTMLElements.
     */
    getElementSelected() {
        if (this.editor) {
            return this.editor.selection.getNode()
        }
    }

    // Will be executed when there is a MCE command executed
    onMceCommand(button, value) {

        // Get the command of the button
        const command = button.command

        // If the editor is not ready yet, we cannot execute commands
        // See https://monitoring.learnbeat.nl/organizations/learnbeat/issues/15928
        if (!this.editor || !this.editor.selection) {
            return
        }

        // Check if the value is not undefined
        if (value !== undefined) {
            // Execute command passing the value to the execCommand function of TinyMCE
            this.editor.execCommand(command, false, value)

            // When the value is undefined
        } else {

            // Execute only the command with the exec command function of TinyMCE
            this.editor.execCommand(command)
        }

        return false;
    }

    async initTinyMce() {
        // Dynamically import tinymce (minified version) dependency since this dependency is quite large.
        // We don't want to increase initial load times of Learnbeat, so we only provide it when a
        // feature on the page needs Wysiwyg.
        await import(
            /* webpackChunkName: "tinymce" */
            'tinymce/tinymce.min'
        ).then(async (tinymce) => {
            // After main tinymce dependency is imported, import all required plugins. If these are all resolved,
            // call the onLoadTinyMce method to initalize the editor.
            await Promise.all([
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/icons/default/icons.min'
                ),
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/themes/silver/theme.min'
                ),
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/models/dom/model.min'
                ),
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/plugins/table/plugin.min'
                ),
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/plugins/lists/plugin.min'
                ),
                import(
                    /* webpackChunkName: "tinymce" */
                    'tinymce/plugins/autoresize/plugin.min'
                )
            ]).then(async () => {
                await this.onLoadTinyMce(tinymce.default)
            }).catch((error) => {
                console.error(error)
                if (navigator.onLine) {
                    window.sentry.captureException(error)
                }
                this.onFailedLoadTinyMce()
            })
        }).catch((error) => {
            console.error(error)
            if (navigator.onLine) {
                window.sentry.captureException(error)
            }
            this.onFailedLoadTinyMce()
        })
    }

    /**
     * onFailedLoadTinyMce
     *
     * Error handler function when tiny mce fails to load.
     * F.e. when user has no internet connection.
     *
     */
    onFailedLoadTinyMce() {
        this.hasFailedToLoad = true

        // Show error message and a button to retry loading
        if (this.retryButton) {
            this.retryButton.enable()
        } else {
            this.retryButton = this.addChildView(
                new Button({
                    label: window.i18n.gettext('Try again'),
                    icon: 'refresh',
                    callback: () => {
                        this.retryButton.disable(true)
                        this.initTinyMce()
                    }
                }),
                '.js-loading-failed'
            )
        }
        this.$('.js-loading-failed').css('display', 'flex')
        this.disableEditor()

        // If another editor finished loading, retry this one as well
        Backbone.View.layout.once('editorLoaded', () => {
            if (this.hasFailedToLoad) {
                this.initTinyMce()
            }
        })
    }

    async onLoadTinyMce(tinymce) {

        // When the previous time tiny mce failed to load
        if (this.hasFailedToLoad) {

            this.$('.js-loading-failed').hide()
            this.enableEditor()

            // And tell the view this time tinymce was successfully loaded
            delete this.hasFailedToLoad
        }

        // Populate toolbar with buttons for the current context.
        // Check if a preset is passed, if so load the corresponding buttons toolbar if it exists
        // Otherwise, load all default buttons for the toolbar
        const buttonsToAdd = this.buttonsPreset && Toolbar.hasOwnProperty(this.buttonsPreset) ?
            Toolbar[this.buttonsPreset] : Toolbar['default']

        // Add color picker if the option is available to the user, and we are in author mode or study-planner
        if (Backbone.Model.user.getSetting('author_color_wysiwyg_available') && (
            APPLICATION === 'author' || this.buttonsPreset === 'studyPlanner')) {
            buttonsToAdd.push('colors')
        }
        this.toolbar.loadButtons(buttonsToAdd)

        const plugins = ['autoresize', 'lists']
        if (this.toolbar.getButtons().table) {
            plugins.push('table');
        }

        // Config and initialize a TinyMCE instance.
        // Config options are documented here: https://www.tiny.cloud/docs/tinymce/6/editor-important-options/
        await tinymce.init({

            // Set focus on editor automatically if autoFocus prop is true and device is not a touch screen.
            auto_focus: this.autoFocus && !('ontouchend' in document) ? this.TinymceId : undefined,

            // Array of names of plugins to load on init.
            plugins,

            // Selector of the textarea element which the TinyMCE editor should mirror.
            selector: `textarea#${this.TinymceId}`,

            // Callback to be executed before TinyMCE instance has rendered.
            setup: this.setupTinyMCE,

            /**
             * Editor Appearance options
             */

            // Do not show "Powered by TinyMCE" branding
            branding: false,
            promotion: false,

            // Do not show menus on right click
            contextmenu: '',

            // Disable element path in status bar.
            elementpath: false,

            // Do not show the default menubar
            menubar: false,

            // Enable manual resizing of the editor on the vertical axis.
            resize: false,

            // Editor theme resource location.
            skin_url: `${window.resourceLocation}/css/wysiwyg`,

            // Do not show the default statusbar.
            statusbar: false,

            // Do not show the default toolbar
            toolbar: false,

            /**
             * Content Appearance options
             */

            // CSS resources to use within the editable area.
            content_css: 'https://use.typekit.net/zar3sch.css' +
                (this.content_css ? `,${window.resourceLocation}/css/wysiwyg/${this.content_css}.css` : ''),

            /**
             * Content Filtering options
             */

            // Which non-standard HTML elements are valid.
            // The <fragment-start>/<template-end> are special custom elements used for authoring Template33
            // (text selection) tasks
            custom_elements: '~fragment-start,~fragment-end',

            // Most of these element types are SVG element types, allowing users to paste SVG code.
            extended_valid_elements: 'a[*],animate[*],animateMotion[*],animateTransform[*],circle[*],clipPath[*],color-profile[*],defs[*],desc[*],ellipse[*],feBlend[*],feColorMatrix[*],feComponentTransfer[*],feComposite[*],feConvolveMatrix[*],feDiffuseLighting[*],feDisplacementMap[*],feDistantLight[*],feFlood[*],feFuncA[*],feFuncB[*],feFuncG[*],feFuncR[*],feGaussianBlur[*],feImage[*],feMerge[*],feMergeNode[*],feMorphology[*],feOffset[*],fePointLight[*],feSpecularLighting[*],feSpotLight[*],feTile[*],feTurbulence[*],filter[*],foreignObject[*],g[*],image[*],line[*],linearGradient[*],marker[*],mask[*],metadata[*],mpath[*],path[*],pattern[*],polygon[*],polyline[*],radialGradient[*],rect[*],set[*],stop[*],style[*],svg[*],switch[*],symbol[*],text[*],textPath[*],title[*],tspan[*],use[*],view[*],div[*],!span[!*],fragment-start,fragment-end[!data-id|data-select]',

            // Don't allow nested aside, fragment-start or fragment-end tags.
            valid_children: '-aside[aside],-fragment-start[fragment-start],-fragment-end[fragment-end]',

            /**
             * Content Formatting options
             */
            // Disable certain text patterns from executing commands
            // https://www.tiny.cloud/docs/tinymce/6/content-behavior-options/#text_patterns
            text_patterns: false,

            /**
             * Spelling options
             */
            browser_spellcheck: APPLICATION === 'author',

            /**
             * URL Handling options
             */

            // Do not use relative urls
            relative_urls: false,

            /**
             * Advanced Editing Behaviors
             */

            // Disable resizing UI for images and tables, since this conflicts with our fixed size classes for images
            // and we don't want give the user control over the size of tables.
            object_resizing: ':not(img),table',

            /**
             * Autoresize Plugin options
             */

            // Use no max height of the editor to automatically resize to.
            max_height: undefined,

            // Min height of the editor.
            // By default we use a small editor. It can be increased when larger content is expected (e.g. text source).
            min_height: this.editorHeight === 'large' ? 200 : 100,

            // Set initial height.
            height: this.editorHeight === 'large' ? 200 : 100,

            // Space below the text editor content
            autoresize_bottom_margin: 24,

            /**
             * Paste Plugin options
             */

            // Commented out line below, reason is that it creates this bug:
            // https://podio.com/dedactnl/platform/apps/learnbeat/items/3340
            //
            // While the documentation says it should be false by default:
            // https://www.tiny.cloud/docs/tinymce/6/copy-and-paste/#paste_data_images
            //
            // Therefore it's an unnecessary option, which creates bugs.
            // paste_data_images: false,

            // Pasted content before it gets parsed into a DOM tree by TinyMCE.
            paste_preprocess: (editor, args) => {

                // When a plain text URL is pasted, turn it into a clickable link
                const pastedContent = args.content.trim()
                if (pastedContent.startsWith('https://') && pastedContent.indexOf(' ') === -1) {
                    args.content = URLTemplate({
                        hyperlink: pastedContent,
                        title: editor.selection.getContent({format: 'text'}) || window.i18n.gettext('link')
                    })
                }
            },

            // Filter pasted content after is already has been parsed into a DOM tree structure by TinyMCE.
            // This removes unwanted elements and attributes, flattens the HTML structure and uploads any images
            // to our own storage bucket.
            paste_postprocess: this.pastePostProcess,

            // Paste behaviour debug tips:
            // When working on an issue where a perticular type of markup gets incorrectly parsed, uncomment the
            // line below to view the pasted content before it passes through the post processing process and
            // inserted into the editor. Note that this does not reflect the true raw content of the clipboard
            // since TinyMCE filters it first. This can be disabled by setting paste_enable_default_filters to false
            // (https://www.tiny.cloud/docs/plugins/paste/#paste_enable_default_filters). It not adviced to disable
            // paste_enable_default_filters in production since this feature validates HTML and improves security
            // by removing XSS threads.
            // paste_preprocess: (editor, args) => {
            //     console.log(args.content);
            // },

            // Disable tooltip for table functions
            table_toolbar: '',
            table_appearance_options: false,
            table_grid: false,
            table_advtab: false,
            table_cell_advtab: false,
            table_row_advtab: false,

            /**
             * Accessibility options
             */

            iframe_aria_text: this.label

        }).then(() => {
            this.trigger('editorLoaded')
            // make 'editorLoaded' event globally accessible
            Backbone.View.layout.trigger('editorLoaded', this.TinymceId);

        });
    }

    /**
     * pastePostProcess
     *
     * Callback to be used as a filter to parse pasted content after is already has been transformed into a proper
     * DOM tree structure by TinyMCE.
     *
     * @param {*} editor        undefined argument
     * @param {Object} args     Plugin relevant data and methods.
     */
    pastePostProcess(editor, args) {
        // Create a NodeIterator object to safely iterate through the DOM tree in serial instead of recursivly,
        // which could cause stack overflow problems with big DOM trees. Add a node filter that only returns the
        // element nodes, since we do not need to process the textual part of the pasted content, only HTML markup.
        const nodeIterator = document.createNodeIterator(args.node, NodeFilter.SHOW_ELEMENT)

        // Process one element at the time.
        let currentNode
        // eslint-disable-next-line no-cond-assign
        while (currentNode = nodeIterator.nextNode()) {
            if (currentNode !== nodeIterator.root) {
                this.pastePostProcessNode(currentNode)
            }
        }
    }

    /**
     * pastePostProcessNode
     *
     * Before pasted content gets inserted into the editor proper, but after it has been parsed into a DOM
     * structure, parse said DOM structure one Node at the time to remove all unwanted attributes and elements.
     * https://www.tiny.cloud/docs/tinymce/6/copy-and-paste/#paste_data_images
     *
     * @param {Node} node    Element or Text node to process
     */
    pastePostProcessNode(node) {

        // Remove any element with an ID attribute starting with docs-internal-guid. Google Docs tends to wrap pasted
        // content with a <span> or <b> tag with this ID. It should be removed since it can mess up the formatting.
        if (/^docs-internal-guid/.test(node.id)) {
            node.outerHTML = node.innerHTML
            return
        }

        // List of AttributeNodes to preserve after all attributes have been removed.
        let keepAttributes = [];

        switch (node.nodeName) {

            // Heading elements
            case 'H1':
            case 'H2':
            case 'H3':
            case 'H4':
            case 'H5':
            case 'H6':
                this.pastePostProcessHeadingElement(node);
                break

            // Anchor elements
            case 'A':
                keepAttributes = this.pastePostProcessAnchorElement(node);
                break

            // Image elements
            case 'IMG':
                keepAttributes = this.pastePostProcessImageElement(node)
                break

            // Span tags
            case 'SPAN':
                keepAttributes = this.pastePostProcessUnderlinedSpanElement(node);
                this.pastePostProcessSupOrSubSpanElement(node);
                break;

            // Misc table elements
            case 'COLGROUP':
            case 'COL':
                keepAttributes = [node.getAttributeNode('span')]
                break

            // Table cells and table header cells
            case 'TD':
            case 'TH':
                keepAttributes = [
                    node.getAttributeNode('rowspan'),
                    node.getAttributeNode('colspan'),
                    node.getAttributeNode('headers'),
                    node.getAttributeNode('scope')
                ]
                break

            // Flatten elements that are not included in the list below.
            default:
                if (!['STRONG', 'EM', 'B', 'I', 'U', 'UL', 'OL', 'LI', 'ASIDE', 'BLOCKQUOTE', 'SUB', 'SUP', 'TABLE', 'THEAD', 'TBODY', 'TFOOT', 'TR', 'BR', 'P'].includes(node.nodeName)) {
                    node.outerHTML = node.innerHTML;
                }
                break
        }

        // Loop through attributes of node and remove all attributes by its name.
        for (let i = node.attributes.length - 1; i >= 0; i--) {
            node.removeAttribute(node.attributes[i].name);
        }
        // Re-add the attributes we want to keep around.
        keepAttributes.forEach((attr) => {
            if (attr) {
                node.setAttributeNode(attr);
            }
        });

    }

    /**
     * pastePostProcessAnchorElement
     *
     * Convert heading elements to bold text if they do not have the h1 tag name.
     *
     * @param {Node} node   Element or Text node to be processed.
     */
    pastePostProcessHeadingElement(node) {
        if (
            node.constructor.name === 'HTMLHeadingElement' &&
            node.nodeName !== 'H1'
        ) {

            // Create new <strong> element with the same content as the heading element
            this.pastePostProcessReplaceElement(node, 'STRONG')

        }
    }

    /**
     * pastePostProcessAnchorElement
     *
     * For anchor elements make sure the href attribute gets preserved after paste post processing has completed.
     * If the href AttributeNode is present, also set the target and rel attributes such that the link always
     * opens on a new page.
     *
     * @param {Node} node   Element or Text node to be processed.
     * @returns {Array}     Array containing a AttributeNodes for href, target, rel and title.
     */
    pastePostProcessAnchorElement(node) {
        if (
            // Remove all attributes from a-tag except href and set the target to '_blank'.
            node.constructor.name === 'HTMLAnchorElement'
        ) {

            if (node.hasAttribute('href')) {
                node.target = '_blank';
                node.rel = 'noopener';
            }

            return [
                node.getAttributeNode('href'),
                node.getAttributeNode('target'),
                node.getAttributeNode('rel'),
                node.getAttributeNode('title')
            ];

        }
        return [];
    }

    /**
     * pastePostProcessImageElement
     *
     * For image elements make sure the src attributes gets preserved after paste post processing has completed,
     * but only if the image has an URL containing 'edu_files/', which refers to a image file on our own server AND
     * it allows for images to be inserted in the current editor. OR if the element has the '.js-expression' class,
     * which denotes it is a render of a LaTeX expression AND expressions are allowed in the current editor.
     * If the src of the image does not qualify, try to upload the source to our own storage bucket async.
     * While the image is being uploaded, give the element a placeholder attribute `data-pasted-src` that has the
     * original URL as its value. If the image has been uploaded, replace this element with an ImageTemplate,
     * using a default position and size class. If the upload fails, delete the image element from the DOM.
     *
     * @param {Node} node   Element of Text node to be processed.
     * @returns {Array}     Array containing AttributeNodes for src, title, alt and class.
     */
    pastePostProcessImageElement(node) {
        const isImageEl = node.constructor.name === 'HTMLImageElement'

        if (
            isImageEl && (
                (
                    this.editorAllowsImages && /edu_files\//.test(node.src)
                ) ||
                (
                    this.editorAllowsExpressions &&
                    node.src.startsWith('data:image/') &&
                    node.classList.contains('js-expression')
                )
            )
        ) {
            return [
                node.getAttributeNode('src'),
                node.getAttributeNode('title'),
                node.getAttributeNode('alt'),
                node.getAttributeNode('class'),
                node.getAttributeNode('data-base64svg'),
                node.getAttributeNode('data-base64formula'),
                node.getAttributeNode('data-allow-fullscreen'),
            ]
        }

        const isPastedImageWithURL = isImageEl && this.editorAllowsImages && Util.validateURL(node.src)
        const isPastedImageWithB64 = isImageEl && this.editorAllowsImages && node.src.startsWith('data:image/')
        if (isPastedImageWithURL || isPastedImageWithB64) {

            const pasteImageUploadModel = new UploadModel({
                uploadType: 'wysiwyg-image',
                doNotCreateInputElement: true,
                customUploadStatusHandler: (model) => {
                    // By the time the file uploading starts the IMG tags from the paste_postprocess step no longer
                    // exists, so we have to look for the inserted element with the same data-pasted-src attribute.
                    const parsedImageNode = this.editor.dom.doc.querySelector(`img[data-pasted-src="${node.dataset.pastedSrc}"]`)
                    if (!parsedImageNode) {
                        return
                    }

                    switch (model.get('status')) {
                        case 'success':
                            // If image has been successfully uploaded to our own storage bucket, replace the image
                            // element with another image element with a src attribute with our edu_files URL,
                            // a default size and position class.
                            parsedImageNode.outerHTML = ImageTemplate({
                                class: true,
                                positionClass: 'source__inline-image-author source__inline-image-author--middle',
                                sizeClass: 'source__inline-image-author--large',
                                filesId: `${model.get('filesId')}/${model.get('hash')}`,
                                fullScreen: true,
                            })
                            break
                        case 'error':
                            // Remove the originally pasted element from the DOM is the image upload failed.
                            parsedImageNode.remove()
                    }
                }
            })
            // Start different upload process depending on if the image src is a b64-encoded image or an URL.
            if (isPastedImageWithB64) {
                pasteImageUploadModel.sendBase64ImageToUploadServer(node.src)
            } else {
                pasteImageUploadModel.sendImageFromInternetToUploadServer(node.src)
            }
            node.dataset.pastedSrc = node.src
            return [
                node.getAttributeNode('data-pasted-src'),
            ]
        }

        node.remove()
        return []
    }

    /**
     * pastePostProcessUnderlinedSpanElement
     *
     * For span elements with underlined text decoration style, save the part of the style attribute that is
     * responsible for the text having an underlined appearance so it can re-added after all other attributes
     * have been removed.
     *
     * @param {Node} node   Element or Text node to be processed.
     * @returns {Array}     Array containing a AttributeNode for style.
     */
    pastePostProcessUnderlinedSpanElement(node) {
        if (
            node.constructor.name === 'HTMLSpanElement' &&
            node.style.textDecoration === 'underline'
        ) {
            node.removeAttribute('style');
            node.style.textDecoration = 'underline';
            return [node.getAttributeNode('style')];
        }
        return [];
    }

    /**
     * pastePostProcessSupOrSubSpanElement
     *
     * For span elements with the super or sub verticalAlign style, replace this span element with relevant
     * sub or sup element.
     *
     * @param {Node} node Element or Text node to be processed.
     */
    pastePostProcessSupOrSubSpanElement(node) {
        if (node.constructor.name === 'HTMLSpanElement') {
            if (node.style.verticalAlign === 'super') {
                this.pastePostProcessReplaceElement(node, 'SUP')
            } else if (node.style.verticalAlign === 'sub') {
                this.pastePostProcessReplaceElement(node, 'SUB')
            }
        }
    }

    /**
     * pastePostProcessReplaceElement
     *
     * Replace an element with a different type of element whilst keeping its content.
     *
     * @param {Node} node Element or Text node to be processed.
     * @param {String} newElementName the tag name for the element to replace node with.
     */
    pastePostProcessReplaceElement(node, newElementName) {
        const newElement = document.createElement(newElementName)
        newElement.innerHTML = node.innerHTML;
        node.parentNode.replaceChild(newElement, node);
    }

    setupTinyMCE(editor) {

        // Load the custom elements in customEl object
        const customEl = this.loadTinyMCECustomElements()
        const customSH = this.loadTinyMCECustomStateHandlers()

        // Initalize commands for toolbar buttons.
        // Commands are what actions that are executed on for the current selection/cursor position
        // in the editor. https://www.tiny.cloud/docs/tinymce/6/editor-command-identifiers/
        for (const buttonView of Object.values(this.toolbar.getButtons())) {
            buttonView.addCommands({editor, customEl, customSH})
        }

        // Trigger change event for external event listeners like the one used in Template2.
        editor.on('change input', _.debounce((e) => {
            this.trigger('change', e)
        }))

        // Add keyboard shortcuts to toggle sub/super-script
        // ("meta" gets turned into ctrl on non-Apple OSes by TinyMCE).
        // meta/ctrl + =
        editor.addShortcut('meta+187', 'Subscript', 'ToggleSubscript')
        // meta/ctrl + shift + =
        editor.addShortcut('meta+shift+187', 'Superscript', 'ToggleSuperscript')

        // alt/option + = to insert a formula (just like MS Word)
        if (this.editorAllowsExpressions) {
            editor.addShortcut('alt+187', 'insertExpression', () => {
                this.toolbar.buttons.expression.onClickButton.call(this.toolbar.buttons.expression)
            })
        }

        if (this.editorAllowsImages) {
            // PastedImageUploader processes image files that get pasted into the editor directly.
            // Not to be confused with pasting HTML that contains images, which is handled in the
            // pastePostProcessImageElement function.
            const pastedImageUploadModel = new UploadModel({
                uploadType: 'wysiwyg-image',
                doNotCreateInputElement: true,
                customUploadStatusHandler: this.onChangeImageUploadStatus
            })
            const pastedImageUploader = new PastedImageUploader(pastedImageUploadModel)
            editor.on('paste', pastedImageUploader.onPaste)
        }

        // Once content is inserted into the editor, disable autocomplete/autocorrect
        // and auto capitalization if noAutoCorrect is enabled.
        if (this.noAutoCorrect) {
            editor.once('SetContent', () => {
                var oBody = editor.getBody();
                // Does not work on versions of iOS earlier than 10.2
                oBody.setAttribute('autocomplete', 'off');
                oBody.setAttribute('autocorrect', 'off');
                oBody.setAttribute('autocapitalize', 'off');
                // Attempts to disable grammarly (source: https://github.com/toptal/picasso/pull/3665)
                oBody.setAttribute('data-gramm_editor', 'false')
                oBody.setAttribute('data-enable-grammarly', 'false')
                oBody.setAttribute('data-gramm', 'false')
            });
        }

        // When there is a change, save the new content to the value of the tinyMCE holder
        editor.on('Change NodeChange ExecCommand SetContent', _.debounce(() => {

            for (const buttonView of Object.values(this.toolbar.getButtons())) {
                // When button's command is active
                if (
                    editor.queryCommandState(buttonView.command) ||
                    editor.queryCommandState(buttonView.commandQueryName)
                ) {

                    // Make button active
                    buttonView.setActive()

                    // When button's command is not active
                } else {

                    // Make button deactive
                    buttonView.setDeactive()
                }
            }

        }))

        /**
         * Listen for dblclick events in general, and disregard
         * those coming from non-img nodes or from formulas
         */
        editor.on('dblclick', doubleClickEvent => {

            if (doubleClickEvent.target.classList.contains('js-expression') && this.editorAllowsExpressions) {
                this.toolbar.buttons.expression.onClickButton.call(this.toolbar.buttons.expression)
                return
            }

            if (
                doubleClickEvent.target.nodeName === 'IMG'
            ) {
                this.openUploadImageModalOnDoubleClick(editor, customEl)
                return
            }

            // When double clicking a link, show the link editor
            if (doubleClickEvent.target.nodeName === 'A') {
                editor.execCommand('insertLink')
                return
            }

        })

        // Since :focus-within does not work through an iframe, set an data attribute when the editor
        // has focus so CSS can apply styling when this is present.
        editor.on('focus', () => {
            this.el.dataset.hasFocus = ''
        })
        editor.on('blur', () => {
            delete this.el.dataset.hasFocus
        })
    }

    /**
     * Defines behaviour for custom commands we have defined for various buttons.
     *
     * @returns {Object} custom element behavior
     */
    loadTinyMCECustomStateHandlers() {

        return {

            // Block handler, will return true if in block
            blockElementHandler(command, template, editor, isTag) {
                editor.addQueryStateHandler(command, () => {

                    // Define the element where the cursor is at
                    const atElement = editor.selection.getNode()

                    let nodeName
                    if (isTag) {
                        nodeName = template;
                    } else if ($(template).get(0).nodeName !== undefined) {
                        nodeName = $(template).get(0).nodeName
                    }

                    if (nodeName === 'IMG') {
                        // Ignore images that are "selected" while nothing is selected.
                        // (a quirk of Firefox)
                        if (editor.selection.isCollapsed()) {
                            return false
                        }
                        // For image which is also used by expression
                        if (atElement?.classList.contains('js-expression')) {
                            return false
                        }
                    }

                    // Check if the at element is the same as the to be inserted element
                    if ($(atElement).is(nodeName)) {
                        return true;
                    }
                    return false

                });
            },

            // Block handler, will return true if in block
            ExpressionHandler(command, editor) {
                editor.addQueryStateHandler(command, () => {
                    // Check if the cursor is currently at an image of an expression.
                    const atElement = editor.selection.getNode()
                    return atElement.nodeName === 'IMG' && atElement.classList.contains('js-expression')
                })
            },

            // Block handler, will return true if in block
            TableHandler(command, editor) {
                editor.addQueryStateHandler(command, () => {
                    // Check if cursor is currently inside of a table element.
                    const atElement = editor.selection.getNode()
                    return atElement.closest('TABLE,TBODY,TR,TD,TH') !== null
                })
            }

        };
    }

    /**
     * Overrides behaviour of some elements with custom behaviour.
     *
     * @returns {Object} custom element behavior functions.
     */
    loadTinyMCECustomElements() {

        return {

            // Block element, will transform any element into itself with no selection
            // If there is selection it will wrap the selection.
            // Block element will remove itself on click when this element is selected
            blockElement(elementTemplate, editor) {

                // Set bookmark at current cursor position in order to restore the position later
                // https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.dom.selection/#getBookmark
                // Use in mode 2, meaning the bookmark location is derrived from offset from the selection
                // https://github.com/tinymce/tinymce/blob/731754d6019938fd74d4d8c233423d3fcdb1148c/modules/tinymce/src/core/main/ts/bookmark/GetBookmark.ts#L214
                const bookmark = editor.selection.getBookmark(2)

                // Get node at the cursor location
                const atElement = editor.selection.getNode()

                // Do tag specific stuff
                switch ($(elementTemplate()).get(0).nodeName) {

                    // For image which is also used by expression
                    case 'IMG':

                        if (atElement.classList.contains('js-expression') || editor.selection.isCollapsed()) {
                            // Return false to stop further execution
                            return false;
                        }

                        break;

                    case 'ASIDE':

                        // Remove nested aside tags.
                        _.each(atElement.childNodes, (childNode) => {
                            if (childNode && childNode.nodeName === 'ASIDE') {
                                childNode.outerHTML = childNode.innerHTML;
                            }
                        });

                        // Return false to stop further execution if tag would
                        // be nested inside another aside tag.
                        if (atElement.parentNode.nodeName === 'ASIDE') {
                            return false;
                        }

                        break;

                }

                // Get selection
                const selectedText = editor.selection.getContent()

                // Check if the at element is the same as the to be inserted element
                if (atElement.nodeName === $(elementTemplate()).get(0).nodeName &&

                    // Only do this if at element is not the body
                    atElement.nodeName !== 'BODY'
                ) {

                    // Flatten element at selection.
                    atElement.outerHTML = atElement.innerHTML

                    // Stop further execution
                    return false;
                }

                if (
                    (selectedText === undefined || selectedText.trim().length < 1) &&
                    atElement.nodeName !== 'BODY'
                ) {
                    const finishedElement = elementTemplate({
                        content: atElement.innerHTML
                    }).trim()
                    // ⬆️ trim trailing unwanted whitespace of template to prevent inserting these
                    // into the editor.

                    // Replace element HTML at selection with finishedElement HTML.
                    atElement.outerHTML = finishedElement

                // Else only transform selection to blockquote
                } else {
                    const finishedElement = elementTemplate({
                        content: selectedText
                    }).trim()
                    // ⬆️ trim trailing unwanted whitespace of template to prevent inserting these
                    // into the editor.

                    // Insert the right element
                    editor.execCommand('mceInsertContent', false, finishedElement);
                }

                // Place cursor to bookmarked location, which should visually the same position
                // the user left their cursor before the content was inserted.
                // https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.dom.selection/#moveToBookmark
                editor.selection.moveToBookmark(bookmark)
            },

            // For the 'insertLink', 'insertImage' and 'insertTable' commands, open a modal to add a new
            // link, image or table if the selection does not contain a link, image or table.
            // Modal view is provided with the an 'Insert' button that inserts the final content at the
            // cursor position with the options from the modal (eg. the number of rows and columns of a table).
            modalComponentElement(title, templateVar, ModalView, editor, extraModalOptions) {

                Backbone.View.Components.modal.open(ModalView, {
                    title,
                    buttons: {
                        [window.i18n.gettext('Cancel')]: {
                            callback: Backbone.View.Components.modal.close,
                            theme: 'secondary',
                        },
                        [window.i18n.gettext('Insert')]: {
                            callback() {
                                // Disable the last (insert) button to prevent inserting multiple times
                                _.last(Backbone.View.Components.modal.getButtons()).disable(true);

                                // Trigger the confirm event
                                Backbone.View.Components.modal.trigger('confirm')

                                // Compile the template for this element
                                const elementTemplate = templateVar

                                const finishedElement = elementTemplate(

                                    // Call getoptions function on the modal's subview
                                    Backbone.View.Components.modal.subView.getOptions()
                                ).trim()
                                // ⬆️ trim trailing unwanted whitespace of template to prevent inserting these
                                // into the editor.

                                // Insert the final element at the cursor position.
                                editor.execCommand('mceInsertContent', false, finishedElement)

                                Backbone.View.Components.modal.close()
                            },
                        }
                    },

                    // The element at the cursor positon (can be null)
                    atElement: editor.selection.getNode(),

                    selection: editor.selection,

                    ...extraModalOptions,
                })

            }
        };
    }

    /**
     * Open upload image modal with previously uploaded image
     * on double click
     *
     * @param {Object} editor TinyMCE editor object
     * @param {Object} customEl TinyMCE custom element object
     */
    openUploadImageModalOnDoubleClick(editor, customEl) {
        customEl.modalComponentElement(

            // Set modal title
            window.i18n.gettext('Upload an image'),

            // Pass the to be inserted template
            ImageTemplate,

            // Define the to-be-opened modal(view)
            UploadImageModal,

            // Pass the editor object
            editor,

            // Image upload/edit options
            {
                simpleImageStyling: this.simpleImageStyling
            }

        );
    }

    // Upload status handler for pasted images. This disables the editor during upload and
    // inserts the image when it was uploaded successfully
    onChangeImageUploadStatus(model) {
        switch (model.get('status')) {

            case 'loading':
                this.disableEditor()
                if (!this.uploadSpinner) {
                    this.uploadSpinner = this.addChildView(
                        new Spinner(),
                        '.js-is-uploading'
                    )
                }
                this.$('.js-is-uploading').css('display', 'flex')
                break

            case 'error':
                this.enableEditor()
                this.$('.js-is-uploading').hide()
                break

            case 'success':
                var newImage = ImageTemplate({
                    class: true,
                    positionClass: 'source__inline-image-author source__inline-image-author--middle',
                    sizeClass: 'source__inline-image-author--large',
                    filesId: `${model.get('filesId')}/${model.get('hash')}`,
                    fullScreen: true
                })
                this.editor.execCommand('mceInsertContent', false, newImage)
                this.enableEditor()
                this.$('.js-is-uploading').hide()
                break
        }
    }

    onDestroy() {
        this.editor?.destroy()
    }

}
