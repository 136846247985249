var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"marketing_site") : depth0), depth0))
    + "\" class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"nav-item") : stack1), depth0))
    + " js-header-item\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"About",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":80},"end":{"line":4,"column":94}}}))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <img src=\"https://static.learnbeat.nl/frontend-images/sign-up-new.svg\" alt=\"\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"background-overlay") : stack1), depth0))
    + " is-login-theme-background-color "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"theme") : depth0), depth0))
    + "\">\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-1\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-2\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-3\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-4\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-5\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-6\"></div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animated-sprite") : stack1), depth0))
    + " sprite-7\"></div>\n</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"background-overlay") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"backgroundImage") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"background-color") : stack1), depth0))
    + " is-login-theme-background-color\"></div>\n</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"background-image") : stack1), depth0))
    + "\" style=\"background-image: url('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"backgroundImage") : depth0), depth0))
    + "')\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">\n    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"marketing_site") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"nav-item") : stack1), depth0))
    + " js-nav-disclaimer js-header-item\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Conditions & Privacy",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":75},"end":{"line":6,"column":104}}}))
    + "</span>\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"button-holder") : stack1), depth0))
    + "\">\n        <div class=\"js-language-selector\"></div>\n   	    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"join-code-button-container") : stack1), depth0))
    + "\">\n            <div class=\"js-join-code\"></div>\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isEqual.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"language") : depth0),"nl_NL",{"name":"isEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":14,"column":24}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"login-wrap-outer") : stack1), depth0))
    + "\">\n   	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"login-wrap") : stack1), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"login-logo") : stack1), depth0))
    + " is-login-theme-text-color is-login-theme-svg-color\">\n            "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"logo") : depth0), depth0)) != null ? stack1 : "")
    + "\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"login-content") : stack1), depth0))
    + "\">\n            <div class=\"js-login\"></div>\n        </div>\n   	</div>\n\n   	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sso-title") : stack1), depth0))
    + "\">\n  		<span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"or login with",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":30,"column":10},"end":{"line":30,"column":32}}}))
    + "</span>\n   	</div>\n    <div class=\"js-sso-bar "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sso-container") : stack1), depth0))
    + "\"></div>\n</div>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"isAnimated") : depth0), depth0),{"name":"isAnimated","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":35,"column":0},"end":{"line":52,"column":15}}})) != null ? stack1 : "");
},"useData":true});