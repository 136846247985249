<script>
    // If true, use styling for larger size
    export let isLarge = false

    // Fragments is an object with values between 0 and 1 for each key:
    // green = green part
    // orange = orange part
    // red = red part
    // gray = gray part
    // blue = blue part
    // black = black part
    export let fragments = {}

    // Optional labels to show as tooltips/title attributes for each fragment
    // By default it's assumed that the green, red and gray parts are used in the context
    // of a student's score. These can be overridden by passing different values for the
    // labels of green, red and/or gray.
    export let labels = {
        green: window.i18n.gettext('Correct'),
        red: window.i18n.gettext('Incorrect'),
        gray: window.i18n.gettext('Has not been graded yet'),
    }

    // Optional markers to indicate things such as median values
    // Should be an array with {label: String, value: Number} objects
    export let markers = []

    // If true, show value of the first fragment next to the bar
    export let showLabel = false

    // If true, assume values should not be represented as percentages.
    // TIP: add a value to the fragments object that has a key that isn't included in colors
    // with the remainder value to calculate the correct total you want to show progress of.
    // For example when fragments: {blue: 120, remainder: 80}, the blue part will show up as
    // 60% of the progress bar.
    export let useAbsoluteValue = false

    // Optional, width of the progress bar (without label) in pixels
    export let width = false

    export function updateBar() {

        // Update the colors that should be included in thte bar
        colors = allColors.filter((color) => Number.isFinite(fragments[color]))

        // Calculate the new sum
        sum = useAbsoluteValue ? _.reduce(fragments, (m, v) => m + v, 0) : 1
    }

    // List of valid color fragments
    const allColors = ['green', 'orange', 'red', 'gray', 'black', 'blue']

    // Colors visible in the progress bar
    let colors = []

    // Total of all values in the progres bar
    let sum

    // Set values for colors and sum
    updateBar()

    function getFragmentWidth(color) {
        return fragments[color] / sum * 100 + '%'
    }

    function getFragmentTitle(color) {
        if (useAbsoluteValue) {
            return fragments[color] + ' ' + (labels[color] || '')
        }

        return Math.round(fragments[color] * 100) + '% ' + (labels[color] || '')
    }

    function getMarkerTitle(marker) {
        if (useAbsoluteValue) {
            return marker.value + ' ' + marker.label
        }

        return Math.round(marker.value * 100) + '% ' + marker.label
    }

    function getMarkerPosition(marker) {
        return marker.value / sum * 100 + '%'
    }

    function setWidth(element) {
        if (width) {
            element.style.width = width + 'px'
        }
    }
</script>

<div class="bar-container">
    {#if showLabel}
        <span class="label" component="progress-bar__label">
            {#if colors.length === 1}
                {#if useAbsoluteValue}
                    {fragments[colors[0]]}
                {:else}
                    {Math.round(fragments[colors[0]] * 100) + '%'}
                {/if}
            {/if}
        </span>
    {/if}
    <div class="bar-outer" use:setWidth>
        <div class="progress-bar bar" class:is-large={isLarge}>
            {#each colors as color}
                <div
                    class="fragment {color}"
                    style:width={getFragmentWidth(color)}
                    title={getFragmentTitle(color)}></div>
            {/each}
        </div>
        {#each markers as marker}
            <div class="marker" title={getMarkerTitle(marker)} style:left={getMarkerPosition(marker)}></div>
        {/each}
    </div>
</div>

<style lang="scss">
    .bar-container {
        display: flex;
        align-items: center;

        .label {
            @include normal-text;

            display: block;
            min-width: 4ch;
            flex-shrink: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 8px;
            text-align: right;
            cursor: inherit;
        }

        .bar-outer {
            position: relative;
            width: 100%;
        }

        .bar {
            height: 12px;
            background-color: $blue-10;
            border-radius: 16px;
            overflow: hidden;
            display: flex;
            width: inherit;
            min-width: 24px;

            &:not(:only-child) {
                margin: 4px 0;
            }

            .fragment {
                background-color: $blue;
                transition: all 0.2s $ease-in-out-quad;
                width: 0%;

                &:global(.green) {
                    background-color: $status-green;
                }

                &:global(.orange) {
                    background-color: $status-orange;
                }

                &:global(.red) {
                    background-color: $status-red;
                }

                &:global(.gray) {
                    background-color: $status-gray;
                }

                &:global(.black) {
                    background-color: $blue-black;
                }
            }

            &.is-large {
                height: 24px;
                border-radius: 12px;

                .fragment {
                    transition-duration: 1s;
                }
            }
        }

        .marker {
            position: absolute;
            width: 4px;
            top: 0;
            bottom: 0;
            transform: translateX(-50%);
            border-radius: 8px;
            background-color: $blue-black;
        }
    }
</style>
